import { useState, useEffect } from "react"

function FormLoading({
    currentState,
}) {

    const [loadMessage, setLoadMessage] = useState(`Submitting form`)
    const [fakePer, setFakePer] = useState(0)

    useEffect(() => {
        if (currentState === "done") {
            setLoadMessage("Finished loading")
            setFakePer(100)
            return
        }
        const loadInterval = setInterval(() => {
            const randomAddition = Math.floor(Math.random() * 8) + 1;
            setFakePer(prevFakePer => {
                const newFakePer = prevFakePer + randomAddition;
                if (newFakePer >= 92) {
                    clearInterval(loadInterval)
                }
                return newFakePer;
            });
        }, 40);
        return () => clearInterval(loadInterval);
    }, [currentState]);

    return (
        <div className="w-full h-full flex justify-center items-center border-[1px] border-gray-400 rounded-[6px]">
            <div className="text-center justify-center items-center flex-col flex">
                <h2 className="text-md mt-4 font-semibold font-sans italic">{loadMessage}... {fakePer}%</h2>
                <div className="w-64 h-4 mt-3 bg-gray-200 rounded-xl">
                    <div style={{ width: `${fakePer}%` }} className="w-16 h-4 pulsating-background rounded-xl duration-100 ease-in-out">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormLoading