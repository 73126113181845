
import React, { useRef, useState } from "react";
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { setChargerIds } from "../redux/reducers/chargersSlice";
import { facilityLogin, userLogin } from "../helpers/apis";

function Login() {

    const dispatch = useDispatch()

    const [facilityUsername, setFacilityUsername] = useState("")
    const [facilityPassword, setFacilityPassword] = useState("")
    const [userUsername, setUserUsername] = useState("")
    const [userPassword, setUserPassword] = useState("")

    const [usernameFacilityError, setUsernameFacilityError] = useState("")
    const [passwordFacilityError, setPasswordFacilityError] = useState("")

    const [usernameUserError, setUsernameUserError] = useState("")
    const [passwordUserError, setPasswordUserError] = useState("")

    async function loginUser(e, facilityOrUser) {

        e.preventDefault()

        setUsernameFacilityError("")
        setPasswordFacilityError("")
        setUsernameUserError("")
        setPasswordUserError("")

        if (facilityOrUser === "facility" && facilityUsername === "") { setUsernameFacilityError("Invalid username"); return }
        if (facilityOrUser === "facility" && facilityPassword === "") { setPasswordFacilityError("Invalid password"); return }
        if (facilityOrUser === "user" && userUsername === "") { setUsernameUserError("Invalid username"); return }
        if (facilityOrUser === "user" && userPassword === "") { setPasswordUserError("Invalid password"); return }

        if (facilityOrUser === "facility") {
            try {
                const body = {
                    "username": facilityUsername,
                    "password": facilityPassword
                }
                const response = await facilityLogin(body)
                const token = await response.data

                if (token.status === "bad") {
                    setUsernameFacilityError("Error logging in user. Check username")
                    setPasswordFacilityError("Error logging in user. Check password")
                    return
                }

                dispatch(setChargerIds(token.chargerID))
                Cookies.set('intellicharge_page_access', [response.data.component_access], { secure: true })
                Cookies.set('jwt_token_intellicharge', token.token, { secure: true, sameSite: 'Strict' });
                Cookies.set('intellicharge_username', facilityUsername, { secure: true });
                Cookies.set('intellicharge_password', facilityPassword, { secure: true, sameSite: 'Strict' });
                Cookies.set('charger_ID', token.chargerID, { secure: true });
                Cookies.set('intellicharge_user_type', 'facility')
                window.location = "/dashboard"
            } catch (error) {
                console.error("error logging in facility user", error)
                setUsernameFacilityError("Error logging in user. Check username")
                setPasswordFacilityError("Error logging in user. Check password")
            }
        }

        if (facilityOrUser === "user") {
            try {
                const body = {
                    "username": userUsername,
                    "password": userPassword
                }
                const response = await userLogin(body)
                const token = await response.data

                if (response.status !== 200 || !token) {
                    setUsernameUserError("Error logging in user. Check username")
                    setPasswordUserError("Error logging in user. Check password")
                    return
                }

                dispatch(setChargerIds(token.chargerID))
                // Cookies.set('intellicharge_page_access', [response.data.component_access], { secure: true })
                // Cookies.set('charger_ID', token.chargerID, { secure: true });
                Cookies.set('jwt_token_intellicharge', token.token, { secure: true, sameSite: 'Strict' });
                Cookies.set('intellicharge_username', facilityUsername, { secure: true });
                Cookies.set('intellicharge_password', facilityPassword, { secure: true, sameSite: 'Strict' });
                Cookies.set('intellicharge_user_type', 'user')
                window.location = "/userDash"
            } catch (error) {
                console.error("error logging in user", error)
                setUsernameUserError("Error logging in user. Check username")
                setPasswordUserError("Error logging in user. Check password")
            }
        }

    }

    return (
        <div className="w-[100vw] h-[100vh] bg-login-gradient flex items-center justify-center">
            <div className="h-[410px] w-96 bg-white rounded-lg shadow-lg p-5 pl-7 pr-7 mr-48">
                <h2 className="w-full text-center font-bold text-3xl mt-4">Facility Login</h2>
                <div className=" mt-8">
                    <h4 className="text-sm text-gray-600 font-semibold">Email or Username</h4>
                    <input onChange={(e) => setFacilityUsername(e.target.value)} value={facilityUsername} name="username" placeholder="Enter email or username" className={` h-9 mt-[2px] text-sm w-full text-lg p-1 border-[1px] rounded-lg mb-2`} />
                    <h2 className="text-xs h-4 font-bold text-red-600 -mt-[6px]">{usernameFacilityError}</h2>
                    <h4 className="text-sm text-gray-600 font-semibold">Password</h4>
                    <input onChange={(e) => setFacilityPassword(e.target.value)} value={facilityPassword} name="password" placeholder="Enter password" autoComplete="current-password" type="password" className={`h-9 mt-[2px] text-sm w-full text-lg p-1 border-[1px] rounded-lg mb-2`} />
                    <h2 className="text-xs h-4 font-bold text-red-600 -mt-[6px]">{passwordFacilityError}</h2>
                    <div className="flex items-center justify-between w-full mt-3">
                        <button className="text-xs text-blue-600 font-semibold -mt-[6px]">
                            Forgot password?
                        </button>
                    </div>
                    <button onClick={(e) => loginUser(e, "facility")} className="w-full h-12 rounded-lg flex items-center justify-center text-lg font-bold text-white bg-primary mt-10">
                        <h3>Log In</h3>
                    </button>
                </div>
            </div>
            <div className="h-[410px] w-96 bg-white rounded-lg shadow-lg p-5 pl-7 pr-7">
                <h2 className="w-full text-center font-bold text-3xl mt-4">User Login</h2>
                <div className=" mt-8">
                    <h4 className="text-sm text-gray-600 font-semibold">Email or Username</h4>
                    <input onChange={(e) => setUserUsername(e.target.value)} value={userUsername} name="username" placeholder="Enter email or username" autoComplete="user-intellicharge-username" className={` h-9 mt-[2px] text-sm w-full text-lg p-1 border-[1px] rounded-lg mb-2`} />
                    <h2 className="text-xs h-4 font-bold text-red-600 -mt-[6px]">{usernameUserError}</h2>
                    <h4 className="text-sm text-gray-600 font-semibold">Password</h4>
                    <input onChange={(e) => setUserPassword(e.target.value)} value={userPassword} name="password" placeholder="Enter password" autoComplete="user-current-password" type="password" className={`h-9 mt-[2px] text-sm w-full text-lg p-1 border-[1px] rounded-lg mb-2`} />
                    <h2 className="text-xs h-4 font-bold text-red-600 -mt-[6px]">{passwordUserError}</h2>
                    <div className="flex items-center justify-between w-full mt-3">
                        <button className="text-xs text-blue-600 font-semibold -mt-[6px]">
                            Forgot password?
                        </button>
                    </div>
                    <button onClick={(e) => loginUser(e, "user")} className="w-full h-12 rounded-lg flex items-center justify-center text-lg font-bold text-white bg-secondary mt-10">
                        <h3>Log In</h3>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Login;