import { useEffect, useState } from "react";

import PermitList from "./filterComponents/permitList";
import Filter from "./filterComponents/filter";
import SelectedPermitsContainer from "./mainComponents/selectedPermitsContainer";

function NewPassManager({
    setShowUserPassInfo,
    userPasses,
    userPassInfoLoading,
    userPassInfoError,
    refreshData
}) {

    const [permitNames, setPermitNames] = useState([])
    const [allUsersInSelectedPermits, setAllUsersInSelectedPermits] = useState([])

    // 
    // These are controlled by the filter, and permitList filter
    // 

    const [selectedPermits, setSelectedPermits] = useState([])
    const [selectedPermitsObjects, setSelectedPermitsObjects] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])

    const [pending, setPending] = useState(true)
    const [accepted, setAccepted] = useState(false)
    const [denied, setDenied] = useState(false)

    // 
    // Get all of the permit names for the permit list filter sub section
    // 

    useEffect(() => {
        if (userPassInfoError || userPassInfoLoading) return
        if (!userPasses) return
        const permitArray = Object.keys(userPasses.data);
        setPermitNames(permitArray)
    }, [userPasses, userPassInfoError, userPassInfoLoading])

    // 
    // Get the data from the selected permits to be shown on right side of modal.
    // 

    useEffect(() => {
        if (!selectedPermits) return;
        if (!userPasses.data) return
        if (selectedPermits.length === 0) return;

        let newArray = [];
        Object.entries(userPasses.data).forEach(([key, value]) => {
            if (selectedPermits.includes(key)) {
                newArray.push(value);
            }
        });
        setSelectedPermitsObjects(newArray);
    }, [selectedPermits, userPasses]);

    // 
    // Get all of the unique usernames from the selected permits for the filter search bar
    // If a user isn't available in search automatically remove them from selected users
    // 

    function getAndSetUniqueUsernames() {
        const allUsernames = Object.values(selectedPermitsObjects).flatMap((users) =>
            users.map((user) => user.username)
        );
        const uniqueUsernamesNoDups = [...new Set(allUsernames)];
        setAllUsersInSelectedPermits(uniqueUsernamesNoDups)
        selectedUsers.forEach((user) => {
            if (!uniqueUsernamesNoDups.includes(user)) {
                setSelectedUsers((prev) => {
                    return prev.filter((person) => person !== user);
                });
            }
        })
    }

    // 
    // Update which names are available to be selected when user changes permits
    // 

    useEffect(() => {
        getAndSetUniqueUsernames()
    }, [selectedPermitsObjects])

    return (
        <div className="w-[1000px] h-[700px] rounded-lg bg-white p-3">
            <div className="w-full flex items-center justify-between">
                <h2 className="text-lg font-semibold">Pass Manager</h2>
                <button onClick={() => setShowUserPassInfo(false)} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div className="h-[600px] w-full mt-2 border-[1px] border-gray-300 grid grid-cols-11">
                <PermitList
                    permitNames={permitNames}
                    setSelectedPermits={setSelectedPermits}
                    selectedPermits={selectedPermits}
                />
                <div className="col-span-8 h-full bg-baseWhite overflow-y-scroll dashboardScrollBar">
                    <Filter
                        allUsersInSelectedPermits={allUsersInSelectedPermits}
                        setSelectedUsers={setSelectedUsers}
                        selectedUsers={selectedUsers}
                        setPending={setPending}
                        pending={pending}
                        setAccepted={setAccepted}
                        accepted={accepted}
                        setDenied={setDenied}
                        denied={denied}
                    />
                    <SelectedPermitsContainer
                        selectedPermitsObjects={selectedPermitsObjects}
                        selectedPermits={selectedPermits}
                        selectedUsers={selectedUsers}
                        pending={pending}
                        accepted={accepted}
                        denied={denied}
                        refreshData={refreshData}
                    />
                </div>
            </div>
        </div>
    )
}

export default NewPassManager