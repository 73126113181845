import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SidebarButton from "./sidebarButton";
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { createPortal } from 'react-dom'
import ReLoginModal from "./reloginModal";

function Sidebar() {
    const [sidebarWidth, setSidebarWidth] = useState(false);
    const [settings, setSettings] = useState(false);
    const [activeIcon, setActiveIcon] = useState("dashboard");
    const [showLoginModal, setShowLoginModal] = useState(false)

    const token = Cookies.get('jwt_token_intellicharge')
    const decodedToken = jwtDecode(token)

    useEffect(() => {
        // Check every 30 seconds to verify JWT is still active
        const timer = setInterval(() => {
            const timestampInSeconds = Math.floor(Date.now() / 1000);
            if (decodedToken.exp - timestampInSeconds <= 130 && decodedToken.exp - timestampInSeconds > 0) {
                setShowLoginModal(true)
            }
        }, 15000)
        return () => clearInterval(timer)
    }, [])

    function logout() {
        Cookies.remove('jwt_token_intellicharge')
        window.location = '/'
    }

    return (
        <>
            <div
                className={`${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                    } h-[100vh] fixed bg-secondary border-r-[0px] border-gray-400 dark:bg-lightestBaseGray z-50 duration-100 ease-in-out`}
                onMouseOver={() => {
                    setSidebarWidth(true);
                }}
                onMouseLeave={() => {
                    setSidebarWidth(false);
                }}
            >
                <div className={`${sidebarWidth === false && settings === false ? "w-16" : "w-16"} duration-100 ease-in-out h-14 p-3 flex items-center border-r-[0px] border-b-[0px] border-gray-400 dark:bg-lightestBaseGray`}>
                    <img src="/Grid_logo_mark.png" />
                </div>
                <div
                    className={`${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                        } h-72 mt-8 duration-150 ease-in-out`}
                >
                    <Link
                        to="/dashboard"

                        className={`flex hover:bg-blue-400 duration-75 ${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                            } ${activeIcon === "dashboard" ? "bg-transparent" : "bg-transparent"}`}
                    >
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"Dashboard"} svgPath={"m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"} />
                    </Link>
                    <Link
                        to="/payments"

                        className={`flex mt-1 hover:bg-blue-400 duration-75 ${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                            } ${activeIcon === "data" ? "bg-blue-500" : "bg-transparent"}`}
                    >
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"Payments"} svgPath={"M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"} />
                    </Link>
                    <Link
                        to="/usertable"

                        className={`flex mt-1 hover:bg-blue-400 duration-75 ${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                            } ${activeIcon === "data" ? "bg-blue-500" : "bg-transparent"}`}
                    >
                        <div className="flex h-10 items-center ml-2.5 p-2 duration-150 ease-in-out">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                            </svg>
                            <div className={`${sidebarWidth === false && settings === false
                                ? "hidden"
                                : "block w-48"
                                }`}>
                                <h2
                                    className={`${sidebarWidth === false && settings === false
                                        ? "hidden"
                                        : "block"
                                        } text-white ml-3 text-lg font-semibold`}
                                >
                                    User Table
                                </h2>
                            </div>
                        </div>
                    </Link>
                    <Link
                        to="/history"

                        className={`flex mt-1 hover:bg-blue-400 duration-75 ${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                            } ${activeIcon === "data" ? "bg-blue-500" : "bg-transparent"}`}
                    >
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"History"} svgPath={"M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"} />
                    </Link>

                    {/* <Link
                        to="/market"

                        className={`flex mt-1 hover:bg-blue-400 duration-75 ${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                            } ${activeIcon === "market" ? "bg-blue-500" : "bg-transparent"}`}
                    >
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"Market"} svgPath={"M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"}/>
                    </Link> */}
                    <Link to="/" onClick={() => logout()} className={`${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                        } fixed flex bottom-4 hover:bg-blue-400 duration-75`}>
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"Logout"} svgPath={"M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"} />
                    </Link>
                    {showLoginModal && (
                        createPortal(
                            <>
                                <div className={`right-0 top-0 bottom-0 left-0 fixed bg-black bg-opacity-30 z-[200] flex items-center justify-center`}>
                                    <ReLoginModal />
                                </div>
                            </>
                            , document.body)
                    )}
                </div>
            </div>
        </>
    );
}

export default Sidebar;
