import React, { useState, useEffect, useRef } from "react";
import Map from "react-map-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl'
import { createMapMarkerGeoJson } from "./service";
import { individualCharger, individualChargerCount, chargerCluster, chargerClusterCount } from "./mapLayers";

const accessToken = 'pk.eyJ1IjoiYWpmYXJsZXk5OSIsImEiOiJjbHEya2lna2cwMHl6MmlwY3ZtaTNvaDZjIn0.MCauwooWDnlP59tyXp1coA'

function UserMap({
    previousChargeData,
    previousChargeDataError,
    previousChargeDataLoading
}) {

    const [mapOptions, setMapOptions] = useState({
        latitude: 40.753762,
        longitude: -111.831477,
        width: '100%',
        height: 560,
        zoom: 14
    })
    const [favoriteCharger, setFavoriteCharger] = useState({
        charger_name: "",
        usage_count: 0
    })

    const mapRef = useRef()

    // 
    // Create the geojson data via service.js, add source and layers to map
    // 
    
    useEffect(() => {
        if (mapRef.current) {
            if (previousChargeDataError || previousChargeDataLoading) return
            if (previousChargeData.data.mapping.length === 0) return
            
            const map = mapRef.current.getMap()
            const geoJson = createMapMarkerGeoJson(previousChargeData.data.mapping, favoriteCharger)

            if(map.getSource('chargers')) {
                map.getSource('chargers').setData(geoJson)
            } else {
                map.addSource('chargers', {
                    type: 'geojson',
                    data: geoJson,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 60,
                    clusterProperties: {
                        sum_usage_count: ['+', ['get', 'usage_count']],
                    },
                })
                
                map.addLayer(individualCharger);
                map.addLayer(individualChargerCount);
                map.addLayer(chargerCluster);
                map.addLayer(chargerClusterCount);
            }

            let popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                // className: 'mapBoxPopup'
            });

            map.on('mouseenter', 'charger-circles', (e) => {
                map.getCanvas().style.cursor = 'pointer';

                const coordinates = e.features[0].geometry.coordinates.slice();
                const name = e.features[0].properties.name;
                const favorite = e.features[0].properties.favorite;
                const usage = e.features[0].properties.usage_count
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                popup.setLngLat(coordinates)
                    .setHTML(`
                        <div style="">
                            <h2 style="font-size: 1.2rem; font-weight: 600; font-style: ${favorite ? "italic" : "normal"}; color: ${favorite ? "#AE8419" : "#000000"};">
    ${name}
</h2>
                        <h3 style="font-size: 0.8rem; color: #000000; margin-top: 8px;">Charging sessions:</h3>
                           <h2 style="font-size: 2rem; color: #000000; margin-top: 4px; font-weight: 600; ">${usage}</h2>
                                </div>
                                <h2 style="font-size: 0.8rem; font-weight: 700; color: #4B5563;"></h2>
                        </div>
                    `)
                    .addTo(map);
            });

            map.on('mouseleave', 'charger-circles', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });

        }
    }, [mapRef, previousChargeData, favoriteCharger])
    
    // 
    // Get the user's most used charger
    // 

    useEffect(() => {
        if (previousChargeDataError || previousChargeDataLoading) return
        if (previousChargeData.data.length === 0) return
        let count = 0
        previousChargeData.data.mapping.forEach((charger) => {
            if (charger.usage_count > count) {
                count = charger.usage_count
                setFavoriteCharger(charger)
            }
        })
    }, [previousChargeData, previousChargeDataError, previousChargeDataLoading])

    return (
        <div className="col-span-12 bg-white rounded-md shadow-md p-4">
            <div className="flex items-center justify-between">
                <h2 className="font-semibold text-lg">Charge Map</h2>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>
            </div>
            <div className="mt-4">
                {/* <h2>Favorite charger: {favoriteCharger.charger_name}</h2> */}
            </div>
            <Map ref={mapRef} initialViewState={{ latitude: mapOptions.latitude, longitude: mapOptions.longitude, zoom: mapOptions.zoom }}
                mapStyle="mapbox://styles/mapbox/streets-v10"
                // // mapStyle="mapbox://styles/ajfarley99/cl62p74a8000015nyql5448dx"
                // mapStyle="mapbox://styles/mapbox/dark-v10"
                mapboxAccessToken={accessToken}
                style={{ height: mapOptions.height, width: mapOptions.width, borderRadius: '6px', marginTop: '16px' }} >
            </Map>
        </div>
    )
}

export default UserMap