import { debounce } from "lodash"
import { useState, useEffect } from "react";

function PermitList({
    permitNames,
    setSelectedPermits,
    selectedPermits
}) {

    const [matchSearchPermits, setMatchSearchPermits] = useState([])

    // 
    // Add or remove the permit name from the selectedPermits state
    // 

    const togglePermit = (name) => {
        setSelectedPermits((prev) => {
            if (prev.includes(name)) {
                return prev.filter((permit) => permit !== name);
            } else {
                return [...prev, name];
            }
        });
    };

    // 
    // Allows users to search for permit by name
    // 

    const changeSearchResults = debounce((event) => {
        event.preventDefault()
        const searchString = event.target.value.toLowerCase()
        const filteredResults = permitNames.filter(
            sensor => sensor.toLowerCase().includes(searchString.toLowerCase())
        );
        setMatchSearchPermits(filteredResults)
    }, 300)

    useEffect(() => {
        setMatchSearchPermits(permitNames)
    }, [permitNames])

    return (
        <div className="col-span-3 h-full bg-baseWhite border-r-[1px] border-gray-300 overflow-y-scroll dashboardScrollBar">
            <div className="fixed w-[255px] bg-white border-b-[1px] border-gray-300 shadow-sm p-2">
                <h2 className="text-md font-semibold">Your Permits</h2>
                <input onChange={(e) => changeSearchResults(e)} type="text" placeholder="Search for permit" className={`text-sm mt-1 pl-2 pr-2 h-7 w-full rounded-full border-[1px] border-gray-400`}></input>
            </div>
            <div className="pt-[73px]">
                {matchSearchPermits.map((name, index) => (
                    <button onClick={() => togglePermit(name)} key={index} className={`${selectedPermits.includes(name) ? "bg-blue-100 border-secondaryAccent -mt-[1px]" : "bg-white"} text-sm p-1 border-[1px] w-full text-start pl-2 pr-2`}>
                        {name}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default PermitList