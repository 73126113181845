
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { getDatesBetween } from "./service";

function Graph({
  data
}) {

  console.log(data)

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    if (!data) return
    if (data.length === 0) return

    // Get all of the dates between the first and last days in the data
    const allDates = getDatesBetween(Object.keys(data[data.length - 1])[0], Object.keys(data[0])[0])
    const flippedData = data.slice().reverse()
    const updatedDataArray = []
    const modifiedDates = allDates.map((date) => date.slice(5));

    allDates.forEach(date => {
      // Find the object in dataArray that contains the key equal to the current date
      const dataObject = flippedData.find(obj => obj.hasOwnProperty(date));
      if (dataObject) {
        updatedDataArray.push(dataObject[date]);
      } else {
        updatedDataArray.push(0);
      }
    });

    // Create the final graph object
    let chartData = {
      labels: modifiedDates,
      datasets: [
        {
          data: updatedDataArray,
          borderColor: 'rgb(0,91,239)',
          backgroundColor: 'rgba(0,91,239,0.5)',
          fill: true,
          pointRadius: 0,
          borderWidth: 2.3,
        }
      ]
    };

    setChartData(chartData)

  }, [data])

  const options = {
    scales: {
      x: {
        grid: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          maxTicksLimit: 24,
          stepSize: 2,
          autoSkip: true,
        }
      },
      y: {
        grid: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          max: 70,
        },
        title: {
          display: true,
          text: "kWh",
          font: {
            size: 14,
            weight: '600',
            lineHeight: 0.3,
          },
          color: 'black',
          padding: 6,
        },
      }
    },
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <div className="w-full bg-white dark:bg-mediumBaseGray">
      <div className="flex h-[332px]">
        <Bar data={chartData} options={options} />
      </div>
      <div className="h-[40px] w-full">

      </div>
    </div>
  )
}


export default Graph