
module.exports = {

  // This merges the policy with the main array,
  // ensuring I can always get the policy 
  mergeArrayValues(array1, array2) {
    let mergedArray = array2.map(item => {
      let foundItem = array1.find(i => i.chargerID === item.chargerID);
      return foundItem ? { ...item, policy: foundItem.policy } : item;
    });
    return mergedArray;
  },

  // This function looks at the weekday key in each policy
  // object, and then asigns it to a day. This function can work with
  // both parking and energy prices, and makes it easier to send to the form
  getPricesForEachDay(array, propertyName) {
    if (!array) return
    let days = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: []
    };
    array.forEach((object) => {
      if (object.weekday in days && propertyName in object) {
        days[object.weekday] = object[propertyName];
      }
      if (object.weekday === 'Every Day' && propertyName in object) {
        Object.keys(days).forEach(day => {
          days[day] = object[propertyName];
        });
      }
      if (object.weekday === 'Weekends' && propertyName in object) {
        days.Saturday = days.Sunday = object[propertyName];
      }
      if (object.weekday === 'Weekdays' && propertyName in object) {
        ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
          days[day] = object[propertyName];
        });
      }
      if (object.weekday === 'Rest of Days' && propertyName in object) {
        Object.keys(days).forEach(day => {
          if (days[day].length === 0) {
            days[day] = object[propertyName];
          }
        });
      }
    });
    return days
  },

  getFeeTypeParking(schedule) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayFeeMap = {};

    // Initialize default placeholder for rest of days
    let restOfDaysFee = null;

    // First pass: Set specific days and capture the "Rest of Days" fee
    schedule.forEach(item => {
        switch (item.weekday) {
            case 'Weekends':
                dayFeeMap['Sunday'] = item.pFee;
                dayFeeMap['Saturday'] = item.pFee;
                break;
            case 'Weekdays':
                ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
                    dayFeeMap[day] = item.pFee;
                });
                break;
            case 'Rest of Days':
                restOfDaysFee = item.pFee; // Capture the fee for rest of days
                break;
            default:
                dayFeeMap[item.weekday] = item.pFee; // Specific day
                break;
        }
    });

    // Second pass: Apply the "Rest of Days" fee to any day not explicitly set
    daysOfWeek.forEach(day => {
        if (!dayFeeMap.hasOwnProperty(day)) {
            dayFeeMap[day] = restOfDaysFee;
        }
    });

    return dayFeeMap;
},


  // This function does the opposite of the function above and groups all
  // of the days together by their independant days, weekends, weekdays, and 
  // other days
  groupDaysByValues(daysObject) {
    if(!daysObject) return
    const groupedDays = {};
    const allDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const uniqueValues = new Set(Object.values(daysObject).map(JSON.stringify));
    if (uniqueValues.size === 1) {
      groupedDays['Every Day'] = JSON.parse([...uniqueValues][0]);
      return groupedDays;
    }
    if (JSON.stringify(daysObject.Saturday) === JSON.stringify(daysObject.Sunday)) {
      groupedDays['Weekends'] = daysObject.Saturday;
    }
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const weekdayValues = weekdays.map(day => daysObject[day]);
    if (new Set(weekdayValues.map(JSON.stringify)).size === 1) {
      groupedDays['Weekdays'] = daysObject.Monday;
    }
    allDays.forEach(day => {
      if (!(day in groupedDays)) { 
        if (!('Weekends' in groupedDays && (day === 'Saturday' || day === 'Sunday')) &&
            !('Weekdays' in groupedDays && weekdays.includes(day))) {
          groupedDays[day] = daysObject[day];
        }
      }
    });
  
    return groupedDays;
  },

  // This function looks at an object and an array with different charger
  // properties and combines each charger into a simplified object with all
  // properties
  combineAPIChargerData(data1, data2) {
    return data2.map(charger => {
      const key = charger.chargerID;
      if (data1[key]) {
        return {
          ...charger,
          ...data1[key]
        };
      }
      return charger;
    });
  }

}