import { useState } from "react";
import { formatTimestamp } from "../service";
import { userPassUpdate } from "../../../helpers/apis";

function PendingTableTile({
    permit,
    request,
    checkedRequests,
    setCheckedRequests,
    refreshData
}) {

    const [savedSuccess, setSavedSuccess] = useState("")

    const handleCheckboxToggle = (requestId) => {
        setCheckedRequests((prevChecked) =>
            prevChecked.includes(requestId)
                ? prevChecked.filter((id) => id !== requestId)
                : [...prevChecked, requestId]
        );
    };

    async function updatePass(status) {
        setSavedSuccess("loading")
        try {
            const response = await userPassUpdate(request.request_id, "0000-00-00 00:00:00", status)
            console.log(response)
            if (response.status === 200) {
                setSavedSuccess("success")
                setTimeout(() => {
                    refreshData()
                    setSavedSuccess("")
                }, 1000)
            }
        } catch (error) {
            console.error("Error updating request on permit", error)
            setSavedSuccess("error")
        }
    }

    return (
        <div
            className="w-full grid grid-cols-2 h-10 border-b-[1px] border-gray-300 pl-3 pr-3"
            onClick={() => handleCheckboxToggle(request.request_id)}
        >
            <div className="col-span-1 grid grid-cols-12 h-10 ">
                <div className="col-span-3 flex items-center">
                    <input
                        checked={checkedRequests.includes(request.request_id)}
                        className="w-4 h-4"
                        type="checkbox"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCheckboxToggle(request.request_id);
                        }}
                    />
                    <h2 className="text-sm ml-3">{request.firstname ? request.firstname : "no name"}</h2>
                </div>
                <div className="col-span-3 flex items-center">
                    <h2 className="text-sm">{request.username ? request.username : "no email"}</h2>
                </div>
                <div className="col-span-2 flex items-center">
                    <h2 className="text-sm">
                        {request.request_date ? formatTimestamp(request.request_date) : "no request date"}
                    </h2>
                </div>
                <div className="col-span-2 flex items-center justify-center">
                    <h2 className="text-sm">{request.response ? request.response : "no id"}</h2>
                </div>
            </div>
            <div className="col-span-1 grid grid-cols-12 h-10">
                <div className="col-span-6 flex items-center">
                    <h2 className="text-sm">Applied for <span className="font-semibold italic text-blue-700">"{permit}"</span> permit.</h2>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        updatePass("accepted")
                    }}
                        disabled={savedSuccess !== ""}
                        className="ml-4 text-xs font-semibold pl-[6px] pr-[6px] p-[2px] rounded-md text-green-700 bg-green-50 border-[1px] border-green-700"
                    >
                        {savedSuccess !== "" ? (
                            <h2>{savedSuccess}</h2>
                        ) : (
                            <h2>Accept</h2>
                        )}
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            updatePass("denied")
                        }}
                        disabled={savedSuccess !== ""}
                        className="ml-2 text-xs font-semibold pl-[6px] pr-[6px] p-[2px] rounded-md text-red-700 bg-red-50 border-[1px] border-red-700"
                    >
                        {savedSuccess !== "" ? (
                            <h2>{savedSuccess}</h2>
                        ) : (
                            <h2>Deny</h2>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PendingTableTile