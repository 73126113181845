
import useSWR, { mutate } from "swr";
import axios from "axios";
import Cookies from "js-cookie"

import Header from "../globalComponents/header"
import Table from "./tableComponents/table";

function UserTable() {

    // 
    // Similar to pass manager/payment page where user can filter chargers, users, etc, and accecpt/deny/change their status 
    // and membership. 
    // filter most recent - oldest
    // filter via permit, user, status, 
    // name, email, uID (other id), request date, vehicle, license, passes, status, 
    // 

    const facility = Cookies.get('intellicharge_username');
    const userPassInfoUrl = `https://gmsapi1communication.com:443/userpass?facility=${facility}`

    // This is a generic axios fetcher function so for the useSWR hook
    const genericSWRFetcher = async (url) => {
        const token = Cookies.get("jwt_token_intellicharge");
        return axios.get(url, {
            headers: { "Content-Type": "application/json", "x-access-token": `${token}` }
        }).then(res => res.data);
    }

    // This hook fetches the user pass information API
    const { data: userPassInfo, error: userPassInfoError, isLoading: userPassInfoLoading } = useSWR(
        userPassInfoUrl,
        () => genericSWRFetcher(userPassInfoUrl),
        { refreshInterval: 10000 }
    );

    // Manually refresh the data when the user updates something
    const refreshData = () => {
        mutate(userPassInfoUrl)
    }

    return (
        <>
            <Header />
            <div className="h-[calc(100vh-56px)] w-full overflow-x-hidden overflow-y-hidden p-4">
                <div className="w-full h-full bg-white small-bold-shadow rounded-lg">
                    <div className="rounded-t-lg h-12 w-full bg-white border-b-[1px] border-gray-300 shadow-md">
                        
                    </div>
                    <Table
                        userPassInfo={userPassInfo}
                        userPassInfoError={userPassInfoError}
                        userPassInfoLoading={userPassInfoLoading}
                        refreshData={refreshData}
                    />
                    <div className="rounded-b-lg h-12 w-full bg-white border-t-[1px] border-gray-300">

                    </div>
                </div>
            </div>
        </>
    )
}

export default UserTable