import { useEffect, useState } from "react";
import useSWR from "swr";
import Cookies from 'js-cookie'
import { chargerDataFetcher, fetch90DayHistoryChargers } from "../../helpers/apis";
import { removeDuplicates, groupByLot } from "../../helpers/componentHelpers/dashboardGraphs";
import { calculateCumulativeKWh, calculateVolts } from "../../helpers/componentHelpers/dashboardGraphs";

import GraphIntermediate from "./graphIntermediate";

function DashboardGraphs({
    chargerDataArray,
    clusterNames,
    simpleChargers,
    formalLotNames
}) {
    // I manage the state of which chargers have been selected to view on the graph
    // from this file to ensure that the state is never reset when the api is fetched.
    const [selectedChargersCumulative, setSelectedChargersCumulative] = useState([0, 1, 2])
    const [selectedClustersCumulative, setSelectedClustersCumulative] = useState([])
    
    const [selectedChargersRealTime, setSelectedChargersRealTime] = useState([0, 1, 2])
    const [selectedClustersRealTime, setSelectedClustersRealTime] = useState([])

    const [selectedChargersHistory, setSelectedChargersHistory] = useState([0, 1, 2])
    const [selectedClustersHistory, setSelectedClustersHistory] = useState([])

    const [cumulativeKWhObject, setCumulativeKWhObject] = useState([])
    const [realtimeObject, setRealtimeObject] = useState([])
    const [energyUsedHistory, setEnergyUsedHistory] = useState([])

    // console.log(selectedChargersRealTime)

    useEffect(() => {
        try {
            let data;
            data = chargerDataArray
            let tempCumulative = {}
            let tempRealTime = {}
            // This removes all duplicates from each power array
            Object.keys(data.power).forEach(key => {
                data.power[key] = removeDuplicates(data.power[key]);
            });
            // This applies cumulative KWh to each array
            Object.keys(data.power).forEach(key => {
                let cumulative = calculateCumulativeKWh(data.power[key])
                tempCumulative[key] = cumulative
            });
            // This calculates the real time consumption
            Object.keys(data.power).forEach(key => {
                let realtime = calculateVolts(data.power[key])
                tempRealTime[key] = realtime
            });
            
            // This turns those objects into arrays
            const cumulative = Object.keys(tempCumulative).map(key => tempCumulative[key]);
            const realtime = Object.keys(tempRealTime).map(key => tempRealTime[key]);
            // console.log(cumulative)

            setCumulativeKWhObject(cumulative)
            setRealtimeObject(realtime)

        } catch (err) {
            console.log("Error removing duplicates and combining data", err)
        }
    }, [chargerDataArray])

    return (
        <>
            <GraphIntermediate
                combinedNoDuplicatesArray={cumulativeKWhObject}

                clustersToShowSetter={setSelectedClustersCumulative}
                clustersToShow={selectedClustersCumulative}
                chargersToShowSetter={setSelectedChargersCumulative}
                chargersToShow={selectedChargersCumulative}

                clusterNames={clusterNames}
                simpleChargers={simpleChargers}

                formalLotNames={formalLotNames}

                title={"Cumulative Energy Consumption"}
                type={"line"}
            />
            <GraphIntermediate
                combinedNoDuplicatesArray={realtimeObject}

                clustersToShowSetter={setSelectedClustersRealTime}
                clustersToShow={selectedClustersRealTime}
                chargersToShowSetter={setSelectedChargersRealTime}
                chargersToShow={selectedChargersRealTime}

                clusterNames={clusterNames}
                simpleChargers={simpleChargers}

                formalLotNames={formalLotNames}

                title={"Real-Time Power Consumption"}
                type={"line"}
            />
            <GraphIntermediate
                combinedNoDuplicatesArray={energyUsedHistory}

                clustersToShowSetter={setSelectedClustersHistory}
                clustersToShow={selectedClustersHistory}
                chargersToShowSetter={setSelectedChargersHistory}
                chargersToShow={selectedChargersHistory}

                clusterNames={clusterNames}
                simpleChargers={simpleChargers}

                formalLotNames={formalLotNames}

                title={"Historic Energy Consumption"}
                type={"line"}
            />
        </>
    )
}

export default DashboardGraphs;