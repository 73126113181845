import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { facilityLogin } from "../helpers/apis";

function ReLoginModal() {

    const [timeUntilLogoff, setTimeUntilLogoff] = useState(1000)

    useEffect(() => {
        // Start timer until JWT is not longer active
        const timer = setInterval(() => {
            const timestampInSeconds = Math.floor(Date.now() / 1000);
            const token = Cookies.get('jwt_token_intellicharge')
            const decodedToken = jwtDecode(token)
            setTimeUntilLogoff(decodedToken.exp - timestampInSeconds)
            if (timestampInSeconds > decodedToken.exp) {
                window.location = '/'
                Cookies.remove('jwt_token_intellicharge')
            }
        }, 500)
        return () => clearInterval(timer)
    }, [])

    function stayLoggedIn() {
        const username = Cookies.get('intellicharge_username')
        const password = Cookies.get('intellicharge_password')
        const loginBody = {
            "username": username,
            "password": password
        }
        fetchUserLogin(loginBody, username, password)
    }

    async function fetchUserLogin(loginBody, username, password) {
        try {
            const response = await facilityLogin(loginBody)
            const token = await response.data
            Cookies.set('jwt_token_intellicharge', token.token, { secure: true, sameSite: 'Strict' });
            Cookies.set('intellicharge_username', username, { secure: true });
            Cookies.set('intellicharge_password', password, { secure: true });
            window.location = "/dashboard"
        } catch (err) {
            console.error("axios POST failed during login" + err)
        }
    }

    function logout() {
        Cookies.remove('jwt_token_intellicharge')
        window.location = '/'
    }

    return (
        <div className="w-96 pt-10 pb-10 rounded-lg bg-white p-2 flex items-center justify-center">
            <div className=" w-full text-center">
                <h2 className="text-lg">You will be logged out in</h2>
                <div className="flex justify-center w-full">
                    <h2 className="font-semibold text-lg">{timeUntilLogoff}</h2>
                    <h2 className="ml-2 text-lg">seconds.</h2>
                </div>
                <h2 className="text-lg mt-8">Do you want to stay logged in?</h2>
                <div className="flex w-full justify-center pl-4 pr-4 mt-8">
                    <button onClick={() => logout()} className="mr-2 h-10 w-full justify-center pl-2 pr-2 bg-red-100 border-[1px] border-red-800 rounded-md font-semibold flex items-center">Logout</button>
                    <button onClick={() => stayLoggedIn()} className="ml-2 h-10 w-full justify-center pl-2 pr-2 bg-primary rounded-md text-white font-semibold flex items-center">Keep Me Logged In</button>
                </div>
            </div>
        </div>
    )
}

export default ReLoginModal;