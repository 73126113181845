import { useState, useEffect } from "react"
import { debounce } from "lodash"

function Filter({
    allUsersInSelectedPermits,
    setSelectedUsers,
    selectedUsers,
    pending,
    setPending,
    accepted,
    setAccepted,
    denied,
    setDenied
}) {

    const [showUsersDropdown, setShowUsersDropdown] = useState(false)
    const [matchSearchUsers, setMatchSearchUsers] = useState([])
    const [selectAllUsers, setSelectAllUsers] = useState(true)

    // 
    // Add or remove the permit name from the selectedPermits state
    // 

    const toggleUser = (name) => {
        setSelectedUsers((prev) => {
            if (prev.includes(name)) {
                return prev.filter((user) => user !== name);
            } else {
                return [...prev, name];
            }
        });
    };

    // 
    // Allows admin user to search for all users of selected permits for filter
    // 

    const changeSearchResults = debounce((event) => {
        event.preventDefault()
        const searchString = event.target.value.toLowerCase()
        const filteredResults = allUsersInSelectedPermits.filter(
            user => user.toLowerCase().includes(searchString.toLowerCase())
        );
        setMatchSearchUsers(filteredResults)
    }, 300)

    useEffect(() => {
        setMatchSearchUsers(allUsersInSelectedPermits)
    }, [allUsersInSelectedPermits])

    // 
    // If all of the users are selected automatically toggle selectAllUsers button
    // 

    useEffect(() => {
        if (selectedUsers.length === allUsersInSelectedPermits.length) {
            setSelectAllUsers(false)
        } else {
            setSelectAllUsers(true)
        }
    }, [allUsersInSelectedPermits, selectedUsers])

    return (
        <div className="fixed w-[698px] h-12 bg-white border-b-[1px] border-gray-300 shadow-sm p-2 flex items-center">
            <h3 className=" font-semibold">Filter: </h3>
            <input checked={pending} onChange={() => setPending(!pending)} type="checkbox" id="pendingCheck" className=" h-[14px] w-[14px] ml-4" />
            <label htmlFor="pendingCheck" className="flex items-center cursor-pointer text-xs bg-yellow-50 border-[1px] border-yellow-600 rounded-md pl-1 pr-1 p-[2px] ml-1">
                <span className="text-yellow-600 italic">Pending</span>
            </label>
            <input checked={accepted} onChange={() => setAccepted(!accepted)} type="checkbox" id="acceptedCheck" className="h-[14px] w-[14px] ml-3" />
            <label htmlFor="acceptedCheck" className="flex items-center cursor-pointer text-xs bg-green-50 border-[1px] border-green-600 rounded-md pl-1 pr-1 p-[2px] ml-1">
                <span className="text-green-600 italic">Accepted</span>
            </label>
            <input checked={denied} onChange={() => setDenied(!denied)} type="checkbox" id="deniedCheck" className="h-[14px] w-[14px] ml-3" />
            <label htmlFor="deniedCheck" className="flex items-center cursor-pointer text-xs bg-red-50 border-[1px] border-red-600 rounded-md pl-1 pr-1 p-[2px] ml-1">
                <span className="text-red-600 italic">Denied</span>
            </label>
            <div>
                <button onClick={() => setShowUsersDropdown(!showUsersDropdown)} className="flex items-center pl-2 pr-2 p-[2px] ml-4 border-[1px] border-gray-400 rounded-full smaller-bold-shadow">
                    <h2 className="mr-2 text-sm">Users</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </button>
                {showUsersDropdown && (
                    <div className="fixed w-72 h-96 bg-baseWhite shadow-2xl ml-4 mt-4 rounded-lg border-[1px] border-gray-400">
                        <div className="p-2 border-b-[1px] bg-white border-gray-400 rounded-tr-lg rounded-tl-lg shadow-sm">
                            <div className="flex items-center justify-between">
                                <h2 className="font-semibold">Filter By User</h2>
                                {selectAllUsers ? (
                                    <button className="text-xs mb-1 font-semibold text-black" onClick={() => setSelectedUsers(allUsersInSelectedPermits)}>select all</button>
                                ) : (
                                    <button className="text-xs mb-1 font-semibold text-black" onClick={() => setSelectedUsers([])}>remove all</button>
                                )}
                            </div>
                            <input onChange={(e) => changeSearchResults(e)} type="text" placeholder="Search for user" className={`text-sm mt-1 pl-2 pr-2 h-7 w-full rounded-full border-[1px] border-gray-400`}></input>
                        </div>
                        <div className="w-full h-[309px] bg-baseWhite rounded-br-lg rounded-bl-lg overflow-y-scroll filterByUserPassManagerScrollBar">
                            {matchSearchUsers.map((user, index) => (
                                <button key={index} onClick={() => toggleUser(user)} className={`${selectedUsers.includes(user) ? "bg-blue-100 border-secondaryAccent -mt-[1px]" : "bg-white"} text-sm p-1 border-[1px] w-full text-start pl-2 pr-2`}>
                                    {user}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Filter