
module.exports = {

    // Fill in any missing dates with the start and end date
    getDatesBetween(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dates = [];
        for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            dates.push(`${year}/${month}/${day}`);
        }
        return dates;
    },

    createMapMarkerGeoJson(markers, favoriteCharger) {
        if(!markers) return
        if(markers.length === 0) return
        let geojsonData = {
            type: "FeatureCollection",
            features: markers.map(point => ({
                type: "Feature",
                properties: {
                    id: point.chargerID,
                    name: point.charger_name,
                    usage_count: point.usage_count,
                    favorite: favoriteCharger.charger_name === point.charger_name ? true : false 
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        point.location.lng !== undefined ? point.location.lng : 0,
                        point.location.lat !== undefined ? point.location.lat : 0
                    ]
                }
            }))
        };
        return geojsonData
    }


}