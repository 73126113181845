import { useEffect, useState } from "react";

import RequestsContainer from "./requestsContainer";

function SelectedPermitsContainer({
    selectedPermitsObjects,
    selectedPermits,
    selectedUsers,
    pending,
    accepted,
    denied,
    refreshData
}) {

    const [selectedPermitsFiltersApplied, setSelectedPermitsFiltersApplied] = useState([])

    // 
    // Apply filters to the selected permit objects
    // 

    useEffect(() => {
        if (selectedPermitsObjects.length === 0) return
        const allowedStatuses = [];
        if (accepted) allowedStatuses.push("accepted");
        if (denied) allowedStatuses.push("denied");
        if (pending) allowedStatuses.push("pending");

        const filtersApplied = selectedPermitsObjects.map(subArray =>
            subArray.filter(item => {
                const itemStatus = item.status === null ? "pending" : item.status;
                return selectedUsers.includes(item.username) && allowedStatuses.includes(itemStatus);
            })
        );
        setSelectedPermitsFiltersApplied(filtersApplied)
    }, [selectedPermits, selectedPermitsObjects, selectedUsers, pending, accepted, denied])

    return (
        <div className="h-full w-full bg-baseWhite pt-12">
            {selectedPermitsFiltersApplied.map((permit, index) => {

                const pendingRequests = permit.filter((request) => request.status !== "accepted" && request.status !== "denied");
                const acceptedRequests = permit.filter((request) => request.status === "accepted");
                const deniedRequests = permit.filter((request) => request.status === "denied");

                if (pendingRequests.length === 0 && acceptedRequests.length === 0 && deniedRequests.length === 0) {
                    return (
                        <div key={index} className="p-2 border-b-[2px] border-gray-400">
                            <h2 className="font-semibold text-md">{selectedPermits[index]}</h2>
                            <h3 className="text-sm mt-1">This permit has no requests.</h3>
                        </div>
                    )
                } else {
                    return (
                        <RequestsContainer
                            key={index}
                            index={index}
                            selectedPermits={selectedPermits}
                            pendingRequests={pendingRequests}
                            acceptedRequests={acceptedRequests}
                            deniedRequests={deniedRequests}
                            refreshData={refreshData}
                        />
                    )
                }
            })}
        </div>
    )
}

export default SelectedPermitsContainer