import React, { useState } from "react"

function ChargeHistory({
    previousChargeData,
    previousChargeDataError,
    previousChargeDataLoading
}) {

    const [showFullHistory, setShowFullHistory] = useState(false)
// 
    // console.log(previousChargeData)

    const itemsToDisplay = showFullHistory ? previousChargeData?.data.sessions : previousChargeData?.data.sessions.slice(0, 3);

    // console.log(itemsToDisplay)

    return (
        <div className="col-span-12 bg-white h-auto shadow-md pb-2 rounded-md ">
            <div className="flex items-center justify-between p-4 pb-1">
                <h2 className="font-semibold text-lg">Charge History</h2>
                <div className="flex items-center">
                    {/* <h3 className="text-sm font-semibold text-gray-700">view all</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
                    </svg> */}
                </div>
            </div>
            <div className={`${showFullHistory ? "pr-[26px]" : "pr-4"} w-full h-10 bg-gray-200 border-t-[1px] border-b-[1px] border-gray-300 mt-3 grid grid-cols-12 pl-4`}>
                <div className="col-span-3 flex items-center">
                    <h2 className="text-sm">Charger</h2>
                </div>
                <div className="col-span-2 flex items-center">
                    <h2 className="text-sm">Vehicle</h2>
                </div>
                <div className="col-span-2 flex items-center">
                    <h2 className="text-sm">Amount</h2>
                </div>
                <div className="col-span-2 flex items-center">
                    <h2 className="text-sm">Duration</h2>
                </div>
                <div className="col-span-2 flex items-center">
                    <h2 className="text-sm">Date</h2>
                </div>
            </div>
            <div className={`${showFullHistory ? "overflow-y-scroll max-h-[672px] dashboardScrollBar" : ""}`}>
                {!previousChargeDataError && !previousChargeDataLoading && itemsToDisplay.map((object, index) => (
                    <div key={index} className="grid grid-cols-12 pl-4 pr-4 pt-3 pb-3 border-b-[1px] border-gray-400 h-14">
                        <div className="col-span-3 flex items-center">
                            <h2 className="text-sm">{object.charger_name ? object.charger_name : "Charger not returned"}</h2>
                        </div>
                        <div className="col-span-2 flex items-center">
                            <h2 className="text-sm">No vehicle</h2>
                        </div>
                        <div className="col-span-2 flex items-center">
                            <h2 className="text-sm">{object.cost}</h2>
                        </div>
                        <div className="col-span-2 flex items-center">
                            <h2 className="text-sm">{object.duration.slice(0, 5)}</h2>
                        </div>
                        <div className="col-span-2 flex items-center">
                            <h2 className="text-sm">{object.date}</h2>
                        </div>
                        <div className="col-span-1 flex items-center justify-end">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
                            <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
                        </svg> */}
                        </div>
                    </div>
                ))}
            </div>
            <div className="h-10 flex items-center justify-center font-semibold pt-2">
                <button onClick={() => setShowFullHistory(!showFullHistory)} className="flex text-sm text-gray-700 items-center">
                    {showFullHistory ? (
                        <>
                            <h2>shrink</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 ml-1 rotate-180">
                                <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
                            </svg>
                        </>
                    ) : (
                        <>
                            <h2>expand</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 ml-1">
                                <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
                            </svg>
                        </>
                    )}
                </button>
            </div>
        </div>
    )
}

export default ChargeHistory