import { useState, useEffect } from "react"
// import { toggle2fa } from "../../helpers/apiFetchFunctions"
// import Cookies from "js-cookie"
// import { jwtDecode } from "jwt-decode"

function SettingsPage() {

    const [authStatus, setAuthStatus] = useState(null)

    // const token = Cookies.get('jwt_token')
    // const decodedToken = jwtDecode(token);

    // 
    // Find out whether or not the user has 2fa enabled and set the state
    // 

    // useEffect(() => {
    //     const authStatus = Cookies.get('2fa_enabled')
    //     if (authStatus === 'false') {
    //         setAuthStatus(false)
    //     } else {
    //         setAuthStatus(true)
    //     }
    // }, [])

    // 
    // Call the put to switch user's 2fa settings
    // 

    // async function toggle2faFunction(digit) {
    //     try {
    //         const response = await toggle2fa(digit, decodedToken.sub.id, token)
    //         if (response.status === 200) {
    //             setAuthStatus(!authStatus)
    //         }
    //     } catch (error) {
    //         console.error("Error toggling 2fa for user", error)
    //     }
    // }

    return (
        <div className="col-span-9 pt-8 pl-8">
            <h1 className="font-semibold text-2xl">Settings</h1>
            <h2 className="text-lg font-semibold mt-4">Security</h2>
            <h3 className="mt-1 text-sm text-gray-700">2 Factor Authentication </h3>
            {/* {authStatus === false ? (
                <button className="text-xs pl-2 pr-2 p-1 font-semibold rounded-md text-white bg-gray-700" onClick={() => toggle2faFunction(1)}>Enable 2fa</button>
            ) : (
                <button className="text-xs pl-2 pr-2 p-1 font-semibold rounded-md text-white bg-gray-700" onClick={() => toggle2faFunction(0)}>Turn off 2fa</button>
            )} */}
            <h2 className="text-lg font-semibold mt-4">Themes</h2>
            {/* <h3 className="font-semibold mt-2 pt-1 border-t-[1px] w-full border-gray-300">Theme </h3> */}
            {/* <button onClick={() => toggleDarkMode("default")}>Default</button>
                <button onClick={() => toggleDarkMode("dark")}>Dark Mode</button> */}
            {/* <div className="flex items-center mt-1">
                <div>
                    <h3 className="text-sm text-gray-700">Light:</h3>
                    <div className="rounded-lg w-20 h-14 bg-default-theme"></div>
                </div>
                <div className="ml-2">
                    <h3 className="text-sm text-gray-700">Dark:</h3>
                    <div className="rounded-lg w-20 h-14 bg-dark-theme"></div>
                </div>
            </div> */}
        </div>
    )
}

export default SettingsPage