import React, { useEffect, useState } from "react";
import Cookies from "js-cookie"
import { genericSWRFetcher } from "../helpers/apis";
import { jwtDecode } from "jwt-decode";
import useSWR from "swr";

import Header from "../globalComponents/header"
import StatsWidget from "./components/statsWidget"
import GraphTile from "./components/graphTile"
import ChargeHistory from "./components/chargeHistory"
import UserInfoRank from "./components/userInfoRank"
import Leaderboard from "./components/leaderboard"
import UserMap from "./components/map"

function Dashboard() {

    const decodedToken = jwtDecode(Cookies.get("jwt_token_intellicharge"))
    // const ninetyDayData = `https://intellichargemobile.com/userdata/historic?username=${decodedToken.username}`
    const ninetyDayData = `https://intellichargemobile.com:443/v2/userdata/tofro?username=${decodedToken.username}&filter_key=1M`

    const { data: previousChargeData, error: previousChargeDataError, isLoading: previousChargeDataLoading } = useSWR(
        ninetyDayData, () => genericSWRFetcher(ninetyDayData),
        { refreshInterval: 1200000 }
    );

    // console.log(previousChargeData)

    return (
        <>
            <Header title={"User Dashboard"} />
            <div className="h-[calc(100vh-56px)] w-full flex justify-center overflow-x-hidden overflow-y-scroll pb-8 pl-4 pr-4">
                <div className="w-[1200px] h-full flex">
                    {/* <div className="flex-grow "> */}
                    <div className="grid grid-cols-12 gap-5 pt-4 w-[70%]">
                        <StatsWidget
                            title={"Energy Used:"}
                            value={previousChargeData?.data?.aggregate?.total_energy ? `${previousChargeData.data.aggregate.total_energy.toFixed(2)} kWh` : "Not returned"}
                        />
                        <StatsWidget
                            title={"Time Charging:"}
                            value={previousChargeData?.data?.aggregate?.total_time_plugged_in ? previousChargeData.data.aggregate.total_time_plugged_in : "Not returned"}
                        />
                        <StatsWidget
                            title={"Total Sessions:"}
                            value={previousChargeData?.data?.aggregate?.total_sessions ? previousChargeData.data.aggregate.total_sessions : "Not returned"}
                        />
                        <GraphTile
                            previousChargeData={previousChargeData}
                            previousChargeDataError={previousChargeDataError}
                            previousChargeDataLoading={previousChargeDataLoading}
                        />
                        <ChargeHistory
                            previousChargeData={previousChargeData}
                            previousChargeDataError={previousChargeDataError}
                            previousChargeDataLoading={previousChargeDataLoading}
                        />
                        <UserMap 
                            previousChargeData={previousChargeData}
                            previousChargeDataError={previousChargeDataError}
                            previousChargeDataLoading={previousChargeDataLoading}
                        />
                        <div className="h-4"></div>
                    </div>
                    <div className="pt-4 ml-4 w-[30%]">
                        <UserInfoRank />
                        {/* <Leaderboard /> */}
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}

export default Dashboard