import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { changeCoords } from "../../../redux/reducers/mapCoordsSlice"

import ExpandedLineGraphs from "./expandedGraphs"

function TableTile({
    charger,
    selectedChargers
}) {

    const [expanded, setExpanded] = useState(false)

    const dispatch = useDispatch()

    function changeMapCoords() {
        dispatch(changeCoords({latitude: 40.753762, longitude: -111.831477}))
        dispatch(changeCoords({latitude: charger?.lat, longitude: charger?.lng}))
    }

    return (
        <div className={`${selectedChargers.includes(charger.id) && expanded && "h-[340px]"} ${!selectedChargers.includes(charger.id) && "hidden h-0"} border-b-[1px] border-gray-400 bg-baseWhite`}>
            <div className={`${selectedChargers.includes(charger.id) ? "h-10" : "hidden h-0"} ${expanded ? "border-b-[1px] border-gray-300 shadow-sm" : "border-b-[0px] border-gray-400"} bg-white w-full grid grid-cols-12 p-1`}>
                <div className="h-full col-span-2 flex items-center">
                    <h2 className="ml-2 text-sm">{charger?.name}</h2>
                </div>
                <div className="h-full col-span-2 flex items-center">
                    {charger?.status === "OFFLINE" && (
                        <div className="ml-1 h-6 pl-1 pr-1 rounded-md bg-red-100 border-[1px] border-red-700">
                            <h2 className="text-sm text-red-700 italic">Offline</h2>
                        </div>
                    )}
                    {charger?.status === "not connected" && (
                        <div className="ml-1 h-6 pl-1 pr-1 rounded-md bg-gray-100 border-[1px] border-gray-700">
                            <h2 className="text-sm text-black italic">Not Connected</h2>
                        </div>
                    )}
                    {charger?.status === "sleeping" && (
                        <div className="ml-1 h-6 pl-1 pr-1 rounded-md bg-blue-100 border-[1px] border-blue-700">
                            <h2 className="text-sm text-blue-700 italic">Sleeping</h2>
                        </div>
                    )}
                    {charger?.status === "connected" && (
                        <div className="ml-1 h-6 pl-1 pr-1 rounded-md bg-blue-100 border-[1px] border-blue-700">
                            <h2 className="text-sm text-blue-700 italic">Sleeping</h2>
                        </div>
                    )}
                    {charger?.status === "charging" && (
                        <div className="ml-1 h-6 pl-1 pr-1 rounded-md bg-green-100 border-[1px] border-green-700">
                            <h2 className="text-sm text-green-700 italic">Charging</h2>
                        </div>
                    )}
                    {charger?.status === "sleeping and not connected" && (
                        <div className="ml-1 h-6 pl-1 pr-1 rounded-md bg-gray-100 border-[1px] border-gray-700">
                            <h2 className="text-sm text-gray-700 italic">Not Connected</h2>
                        </div>
                    )}
                </div>
                {/* <div className="h-full col-span-1 flex items-center">
                    <h2 className="ml-[8px] text-sm">Authorized</h2>
                </div> */}
                <div className="h-full col-span-2 flex items-center">
                    <h2 className="ml-[12px] text-sm">{charger?.request[charger.request.length - 3]}</h2>
                </div>
                {charger?.request?.length > 0 ? (
                    <div className="h-full col-span-3 flex items-center">
                        <h2 className="ml-3 text-sm">{charger?.request[charger.request.length - 1]}</h2>
                    </div>
                ) : (
                    <div className="h-full col-span-3 flex items-center">
                        <h2 className="ml-3 text-sm">No user</h2>
                    </div>
                )}
                <div className="col-span-2">

                </div>
                <div className="col-span-1 flex items-center justify-end">
                    {/* <button onClick={() => setExpanded(!expanded)}>
                        {expanded ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25" />
                            </svg>
                        )}
                    </button> */}
                    <button onClick={() => changeMapCoords()} className="ml-2 mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
                        </svg>
                    </button>
                </div>
            </div>
            {/* {expanded && (
                <div className="h-[299px] p-3 w-full bg-baseWhite flex justify-end">
                    <div className="bg-white p-2 rounded-md">
                        <h2 className="text-sm font-semibold ml-1">Real-Time Power Consumption</h2>
                        <ExpandedLineGraphs
                            title={"Real Time Power Consumption"}
                            graphArray={[charger.power]}
                            graphDimensions={{ height: 135, width: 500 }}
                            chargersToShow={[0]}
                        />
                    </div>
                </div>
            )} */}
        </div>
    )
}

export default TableTile