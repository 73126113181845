import { useState, useEffect } from "react";
import { debounce } from 'lodash';
import { createPortal } from 'react-dom'

import StatusSummaries from "./cosmeticComponents/statusSummaries";
import TableKeys from "./cosmeticComponents/tableKeys";
import TableTile from "./cosmeticComponents/tableTile";

function DashboardTable({ allChargerStatuses, chargerDataArray, formalLotNames }) {

    const [expandTable, setExpandTable] = useState(false)
    const [showClusters, setShowClusters] = useState(false)
    const [clusteredChargersNames, setClusteredChargersNames] = useState([])
    const [selectedGroup, setSelectedGroup] = useState([])
    const [selectedChargers, setSelectedChargers] = useState([])
    const [searchString, setSearchString] = useState("")

    const clusterColors = {
        0: "bg-[#e5f2f2]",
        1: "bg-[#f2fbef]",
        2: ""
    }

    // 
    // This creates an array of only the charger lot names
    // Set selectedGroup to the first lot in the array so the table has data
    // 

    useEffect(() => {
        if (!chargerDataArray) return
        if (chargerDataArray.length === 0) return
        addOrRemoveLot(chargerDataArray[0].lot)
        const updatedChargerNames = [...new Set(chargerDataArray.map((charger) => charger.lot))];
        setClusteredChargersNames(updatedChargerNames)
    }, [chargerDataArray]);

    // 
    // This function checks to see if the user left any chargers active during their last 
    // session, and will preset the dashboard to match based off local storage
    // 

    // useEffect(() => {
    //         if(!chargerDataArray || chargerDataArray.length === 0) return
    //         let inLocalStorage = []
    //         chargerDataArray.forEach(entry => {
    //             let local = localStorage.getItem(`intellichargeDashboardTable${entry.id}`)
    //             if(local === 'true') {
    //                 inLocalStorage.push(entry.id)
    //             }
    //         });
    //         setSelectedChargers(inLocalStorage)
    // }, [chargerDataArray])

    // 
    // This function handles when the user presses a single charger instead of a cluster
    // It adds/removes the charger from the selectedChargers state, as well as sets the lot
    // 

    function addOrRemoveSelectedCharger(charger) {
        try {
            if (selectedChargers.includes(charger.id)) {
                setSelectedChargers(prev => {
                    localStorage.setItem(`intellichargeDashboardTable${charger.id}`, false)
                    return prev.filter(existingID => existingID !== charger.id);
                })
                setSelectedGroup(prev => {
                    return prev.filter(existingGroup => existingGroup !== charger.lot)
                })
            }
            if (!selectedChargers.includes(charger.id)) {
                setSelectedChargers(prev => {
                    localStorage.setItem(`intellichargeDashboardTable${charger.id}`, true)
                    return [...prev, charger.id];
                })
                let newArray = [...selectedChargers, charger.id]
                const chargersWithMatchingLot = chargerDataArray.map((item, index) => item.lot.trim() === charger.lot ? item.id : null).filter(index => index !== null);
                const containsAll = chargersWithMatchingLot.every(num => newArray.includes(num));
                if (containsAll) {
                    setSelectedGroup(prev => {
                        return [...prev, charger.lot]
                    })
                }
            }
        } catch (error) {
            console.error("Error adding/removing chargers")
            return
        }
    }

    // 
    // This function adds or removes all charger ids with the same lot from the graph.
    // This handles pressing a cluster instead of just an individual charger
    // 

    function addOrRemoveLot(lot) {
        if (!chargerDataArray || chargerDataArray.length === 0) return
        const chargersWithMatchingLot = chargerDataArray.map((item, index) => item.lot.trim() === lot ? item.id : null).filter(index => index !== null);
        let includesAll = true
        let doesntIncludeArray = []
        chargersWithMatchingLot.forEach((id) => {
            if (!selectedChargers.includes(id)) {
                includesAll = false
                doesntIncludeArray.push(id)
            }
        })
        if (includesAll) {
            setSelectedChargers(prevSelectedChargers =>
                prevSelectedChargers.filter(id => !chargersWithMatchingLot.includes(id))
            );
            setSelectedGroup(prevGroups =>
                prevGroups.filter(groupName => groupName !== lot)
            );
        } else {
            setSelectedChargers(prevSelectedChargers => {
                const newNumbers = doesntIncludeArray.filter(number => !prevSelectedChargers.includes(number));
                return [...prevSelectedChargers, ...newNumbers];
            });
            setSelectedGroup(prevGroups => {
                return [...prevGroups, lot]
            })
        }
    }

    // 
    // This changes the search string for the select chargers table search
    // 

    const changeSearchString = debounce((searchString) => {
        const string = searchString.toLowerCase()
        setSearchString(string)
    }, 200)

    return (
        <>
            <StatusSummaries
                allChargerStatuses={allChargerStatuses}
            />
            {/* ${expandTable ? "h-[600px]" : "h-[280px]"} */}
            <div className={`col-span-12 rounded-lg  bg-white border-[0px] small-bold-shadow border-gray-400 max-h-[500px]
                 duration-100 ease-in-out`}>
                <div className="w-full h-full">
                    <h2 className="relative font-semibold text-md text-black dark:text-textWhite font-sans p-4 pb-0">Dashboard Table</h2>
                    <div className=" w-full p-3 pt-1 pl-3 h- border-b-[0px] border-gray-400 flex items-center justify-between relative">
                        <button
                            name="startTime"
                            onClick={() => setShowClusters(!showClusters)}
                            className="border-[1px] flex items-center mr-[6px] dark:bg-sidebarDark  bg-white dark:bg-lightestBaseGray h-7 pl-2 pr-2 rounded-full smaller-bold-shadow border-gray-400 dark:border-black text-sm text-black dark:text-secondaryTextWhite"
                        >
                            Select Chargers
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </button>

                        {showClusters && (
                            <div className="h-96 w-96 bg-baseWhite chargerSelectorDropdown rounded-md border-[1px] border-gray-400 absolute mt-[426px] z-[80]">
                                <div className="p-2 bg-white rounded-tr-md rounded-tl-md shadow-sm z-30 border-b-[1px] border-gray-300">
                                    <input onChange={(e) => changeSearchString(e.target.value)} type="text" className="w-full rounded-full h-8 border-[1px] border-gray-500 bg-white pl-2 pr-2 text-sm" placeholder="Search for charger or cluster"></input>
                                </div>
                                <div className="w-full h-[calc(100%-49px)] rounded-br-md rounded-bl-md overflow-y-scroll ">
                                    {clusteredChargersNames.map((name, higherIndex) => (
                                        <div key={higherIndex} className="">
                                            <button onClick={() => { addOrRemoveLot(name) }} className={`${selectedGroup.includes(name) ? `${clusterColors[higherIndex]}` : "bg-gray-100"} ${name.toLowerCase().includes(searchString.toLowerCase()) || formalLotNames[name].toLowerCase().includes(searchString.toLowerCase()) ? "" : "hidden"} w-full text-md font-semibold text-left border-b-[1px] pl-2 pr-2 p-1 border-gray-300`}>
                                                {formalLotNames[name]}
                                            </button>
                                            {chargerDataArray.length > 0 && chargerDataArray.map((charger, index) => (
                                                <button onClick={() => addOrRemoveSelectedCharger(charger)} className={`${charger.lot !== name && "hidden"} ${selectedChargers.includes(charger.id) ? `${clusterColors[higherIndex]}` : " bg-gray-100"} ${charger.name.toLowerCase().includes(searchString.toLowerCase()) ? "" : "hidden"} w-full text-sm text-left border-b-[1px] pl-2 pr-2 p-1 border-gray-300`} key={index}>
                                                    {charger.name}
                                                </button>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-black">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-black ml-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                        {expandTable ? (
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setExpandTable(false)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-black ml-1 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setExpandTable(true)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-black ml-1 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                            </svg>
                        )}
                    </div>
                </div>
                <TableKeys />
                <div className={`w-full max-h-[380px] min-h-[228px] overflow-y-scroll dashboardScrollBarGraphRounded pb-2 duration-100 ease-in-out`}>
                    {selectedChargers.length === 0 ? (
                        <>
                            <div className="w-full h-[220px] p-3 pb-1">
                                <div className="h-full w-full bg-blue-50 bg-opacity-70 border-[2px] border-blue-300 rounded-md shadow-sm p-3">
                                    <h2 className="text-md font-semibold">No clusters or chargers selected</h2>
                                    <h2 className="text-sm">Press the select chargers dropdown to select a cluster or charger.</h2>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {chargerDataArray.map((charger, index) => (
                                <TableTile
                                    charger={charger}
                                    selectedChargers={selectedChargers}
                                    key={index}
                                />
                            ))}
                        </>
                    )}
                </div>
                {showClusters && (
                    createPortal(
                        <div onClick={() => setShowClusters(false)} className={`right-0 top-0 bottom-0 left-0 fixed z-[50]`}>

                        </div>
                        , document.body)
                )}
            </div>
        </div >
        </>
    )
}

export default DashboardTable;