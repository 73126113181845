import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver'

// export function exportDataArrayToExcelHistory(dataArray, chargers, simpleChargers) {
//     if (!dataArray || !chargers) return;
//     const uniqueChargers = [...new Set(chargers)];
//     const workbook = XLSX.utils.book_new();
//     uniqueChargers.forEach(chargerIndex => {
//         const chargerData = dataArray[chargerIndex];
//         if (chargerData && chargerData.length > 0) {
//             const headers = [['time', 'value', 'kW']];
//             const data = chargerData.map(dataItem => [
//                 dataItem.time || '',  // Default to empty string if no time
//                 dataItem.value || '', // Default to empty string if no value
//                 'kW' // Static "kW" value for each entry
//             ]);
//             const worksheetData = [...headers, ...data];

//             // Create a worksheet from the data
//             const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

//             // Append the worksheet to the workbook
//             XLSX.utils.book_append_sheet(workbook, worksheet, `${simpleChargers[chargerIndex].name}`);
//         }
//     });
//     if (workbook.SheetNames.length === 0) {
//         return;
//     }
//     const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
//     function s2ab(s) {
//         const buf = new ArrayBuffer(s.length);
//         const view = new Uint8Array(buf);
//         for (let i = 0; i < s.length; i++) {
//             view[i] = s.charCodeAt(i) & 0xFF;
//         }
//         return buf;
//     }
//     saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `historic_energy_consumption.xlsx`);
// }

export function exportDataArrayToExcelHistory(dataArray, chargers, simpleChargers, startDate, endDate) {
    if (!dataArray || !chargers) return;

    const uniqueChargers = [...new Set(chargers)];
    const workbook = XLSX.utils.book_new();

    uniqueChargers.forEach(chargerIndex => {

        const chargerId = simpleChargers[chargerIndex].chargerId;
        const chargerData = dataArray[chargerId];

        if (chargerData && chargerData.length > 0) {
            // Top row header with date range
            const topHeader = [[`${startDate} - ${endDate}`]];

            // Column headers for the data
            const headers = [['user', 'station name', 'station id', 'energy', 'energy unit', 'start time', 'end time', 'energy price', 'sleep time', 'sleep price', 'parking price']];

            // Data rows
            const data = chargerData.map(dataItem => [
                dataItem.user || '',  
                dataItem.station_name || '', 
                dataItem.stationID || '',
                dataItem.energy || '',
                'kW',
                dataItem.start_time || '',
                dataItem.unplug_time || '',
                dataItem.energy_price || 0,
                dataItem.sleep_time || '',
                dataItem.sleeping_price || 0,
                dataItem.parking_price || 0
            ]);
            const worksheetData = [...topHeader, ...headers, ...data];
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            if (worksheet['!merges'] === undefined) worksheet['!merges'] = [];
            worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: headers[0].length - 1 } });
            const topHeaderCell = worksheet['A1'];
            if (topHeaderCell) {
                topHeaderCell.s = { font: { bold: true } };
            }
            XLSX.utils.book_append_sheet(workbook, worksheet, `${simpleChargers[chargerIndex].name}`);
        }
    });
    if (workbook.SheetNames.length === 0) return
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `charger_history_${startDate}-${endDate}.xlsx`);
}




export function exportDataArrayToExcelReceipts(dataArray, charger) {
    if (!dataArray) return;
    const fullArray = dataArray.flat();
    if (fullArray.length === 0) return;
    const headers = Object.keys(fullArray[0]);
    const data = fullArray.map(item => headers.map(header => item[header]));
    const worksheetData = [headers, ...data];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, `${charger}`);
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${charger}_receipts.xlsx`);
}