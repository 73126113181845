

module.exports = {

    // 
    // This function turns the user charger object {{}, {}, {}} into an array for better mapping 
    // 

    combineChargerData(data) {
        if(!data) return
        const { markers, names, power, request, status, lot, nameID } = data;
        const combinedData = Object.keys(markers).map(chargerID => {
            return {
                chargerID: chargerID,
                lng: markers[chargerID].lng,
                lat: markers[chargerID].lat,
                facility: markers[chargerID].facility,
                id: markers[chargerID].id,
                name: names[chargerID],
                power: power[chargerID],
                request: request[chargerID],
                status: status[chargerID],
                lot: lot[chargerID],
                nameID: nameID[chargerID]
            };
        });
        return combinedData;
    },

    // 
    // This counts the number of each type of status for the Dashboard
    // 

    getAllChargerStatuses(data) {
        let connected = 0
        let not_connected = 0
        let sleeping = 0
        let charging = 0
        let offline = 0
        data.forEach((dataset) => {
            if (dataset.status === "connected") {connected += 1}
            if (dataset.status === "not connected") {not_connected += 1}
            if (dataset.status === "sleeping") {sleeping += 1}
            if (dataset.status === "charging") {charging += 1}
            if (dataset.status === "offline") {offline += 1}
            if (dataset.status === "sleeping and not connected") {sleeping += 1}
        })
        return { connected, not_connected, sleeping, charging, offline }
    },

    // 
    // This calculates the max amps for the amps widget
    // 

    getMaxPowerAmps(data) {
        let tempMax = -Infinity
        data.forEach((dataset) => {
            dataset.power.forEach((object) => {
                if(parseFloat(object.value) > tempMax) {
                    tempMax = object.value
                }
            })
        })
        if(tempMax === -Infinity) {
            return 0
        }
        return tempMax
    },

    // 
    // Loop through the requests and get the number of requests
    // 

    getNumberOfRequests(data) {
        let num = 0
        data.forEach((dataset) => {
            let length = dataset.request.length / 4
            num += length
        })
        return num
    }

}