import { PolicyFormTimes } from "../helpers/strings"
import { useState } from "react"
import { CalenderInputDays, abbreviatedDayNames } from "../helpers/strings";

import AllDayInputs from "./allDayInputs";

function Test() {

    const [selectedDayData, setSelectedDayData] = useState(Array(24).fill(0))

    const [shiftSelectedIndexesSunday, setShiftSelectedIndexesSunday] = useState([]);

    const [selectedDay, setSelectedDay] = useState("Sunday")

    const handleClick = (index, event) => {
        if (event.shiftKey) {
            setShiftSelectedIndexesSunday((prevIndexes) => {
                if (prevIndexes.length === 0) {
                    return [index];
                } else {
                    const lastSelectedIndex = prevIndexes[prevIndexes.length - 1];
                    const start = Math.min(lastSelectedIndex, index);
                    const end = Math.max(lastSelectedIndex, index);
                    const range = [];
                    for (let i = start; i <= end; i++) {
                        range.push(i);
                    }
                    return Array.from(new Set([...prevIndexes, ...range]));
                }
            });
        } else {
            if (shiftSelectedIndexesSunday.includes(index)) {
                setShiftSelectedIndexesSunday([]);
            } else {
                setShiftSelectedIndexesSunday([index]);
            }
        }
    };

    const handleInput = (index, value) => {
        if (!shiftSelectedIndexesSunday.includes(index)) {
            setSelectedDayData((prevData) => {
                const newData = [...prevData];
                newData[index] = value;
                return newData;
            });
        } else {
            setSelectedDayData((prevData) => {
                const newData = [...prevData];
                shiftSelectedIndexesSunday.forEach((index) => {
                    newData[index] = value;
                });
                return newData;
            });
        }
    };

    return (
        <div className="w-full h-full p-6">
            <div className="p-4 rounded-lg bg-white w-[512px]">
            <div className=" bg-white w-[480px] border-[1px] border-gray-300 p-2 rounded-lg">
            <div className="h-8 flex justify-between pl-3 pr-4 -ml-2 -mr-2 mt-2 border-b-[1px] border-t-[1px] border-gray-400 bg-gray-200">
                {CalenderInputDays.map((day, index) => (
                    <button key={index} onClick={() => setSelectedDay(day)} className={`${day === selectedDay ? "font-semibold" : ""} text-md`}>{abbreviatedDayNames[day]}</button>
                ))}
            </div>
                <AllDayInputs
                    day={"Sunday"}
                    selectedDayData={selectedDayData}
                    handleClick={handleClick}
                    handleInput={handleInput}
                    shiftSelectedIndexes={shiftSelectedIndexesSunday}
                />
            </div>
            </div>
        </div>
    )
}

export default Test