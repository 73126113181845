import React, { useState, useEffect } from "react"
import { postNewChargerPolicy, updateChargerPolicy } from "../../../helpers/apis"
import { newPolicyFormEnergy, newPolicyFormParking, newPolicyFormSleeping } from "../../../helpers/random"
import { createPortal } from "react-dom"
import { getPricesForEachDay } from "../../../helpers/payment"

import SleepingInput from "./policyFormComponents/sleepingInput"
import ConfirmDelete from "../confirmDelete"
import NewEnergyInput from "./NEW-PolicyFormComponents/energyInput"
import NewParkingInput from "./NEW-PolicyFormComponents/parkingInput"
import SleepingGraceInput from "./NEW-PolicyFormComponents/sleepingGraceInput"
import FormLoading from "./NEW-PolicyFormComponents/formLoading"

function PolicyForm({
    setSelectedPolicyID,
    selectedPolicyID,
    policyToBeShown,
    newPolicy,
    passes,
    chargers,
    policies,
    refreshData
}) {

    const [policyName, setPolicyName] = useState("new policy")
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const [loading, setLoading] = useState(false)
    const [loadingState, setLoadingState] = useState("")
    const [error, setError] = useState("")

    const [energyObjects, setEnergyObjects] = useState([
        { weekday: "Sunday", energy: Array(24).fill(0) },
        { weekday: "Monday", energy: Array(24).fill(0) },
        { weekday: "Tuesday", energy: Array(24).fill(0) },
        { weekday: "Wednesday", energy: Array(24).fill(0) },
        { weekday: "Thursday", energy: Array(24).fill(0) },
        { weekday: "Friday", energy: Array(24).fill(0) },
        { weekday: "Saturday", energy: Array(24).fill(0) }
    ])
    const [parkingObjects, setParkingObjects] = useState([
        { weekday: "Sunday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Monday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Tuesday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Wednesday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Thursday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Friday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Saturday", pFee: 'Per Hour', parking: Array(24).fill(0) }
    ])
    const [sleepingObjects, setSleepingObjects] = useState([
        { weekday: "Sunday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Monday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Tuesday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Wednesday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Thursday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Friday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Saturday", grace: 0, sType: 'time', sleeping: 0}
    ])


    // 
    // This useEffect checks to see if the user opened a new policy, 
    // or if the policy already exists. Set objects accordingly
    // 

    useEffect(() => {
        if (selectedPolicyID === -2) {
            setEnergyObjects(newPolicyFormEnergy)
            setParkingObjects(newPolicyFormParking)
            setSleepingObjects(newPolicyFormSleeping)
            setPolicyName("New Policy")
            setError("")
            setLoading(false)
            return
        }
        if (policyToBeShown?.data?.length !== 0 && policyToBeShown?.data?.energy) {
            setEnergyObjects(policyToBeShown.data.energy)
            setParkingObjects(policyToBeShown.data.parking)
            setSleepingObjects(policyToBeShown.data.sleeping)
            setPolicyName(policyToBeShown?.policy)
            setError("")
            setLoading(false)
        }
    }, [policyToBeShown, selectedPolicyID])

    // 
    // Function that formats the policy data into an acceptable format for the server
    // while also handling all errors, and if the user is submitting a new form
    // or updating an existing form 
    // 

    async function submitOrSaveForm(type) {
        // setLoadingSubmit(true)
        // setFinishedSubmitting(false)
        setLoading(true)
        let data = {
            name: policyName,
            values: energyObjects,
            valuesP: parkingObjects,
            valuesS: sleepingObjects
        }
        data.values.forEach((object) => {
            object.parking = Array(24).fill(0)
            object.green = ""
            object.idle = 20
            object.member = 0
        })
        data.valuesP.forEach((object) => {
            object.energy = Array(24).fill(0)
            object.green = ""
            object.idle = 20
            object.member = 0
        })
        data.valuesS.forEach((object) => {
            object.member = 0
            object.chargeType = "time"
        })
        try {
            const apiCall = type === "submit" ? postNewChargerPolicy : updateChargerPolicy;
            const postedPolicy = await apiCall(data);
            if (postedPolicy === "server error") {
                setLoading(false)
                setError("There was an error submitting the policy to the server.")
                return
            }
            if (postedPolicy.status === 200) {
                setLoadingState("done")
                setTimeout(() => {
                    setLoadingState("")
                    setLoading(false)
                    setError("success")
                    refreshData()
                }, 400)
                setTimeout(() => {
                    setError("")
                }, 2000)
            }
        } catch (error) {
            console.error("error posting new policy in policy form", error)
            setError("There was an error submitting the policy to the server.")
            setLoading(false)
        }
    }

    return (
        <div className="w-full h-full overflow-y-scroll policyFormScrollBar rounded-lg">
            <div className="flex items-center justify-between absolute z-50 w-[493px] pl-2 pr-2 pt-1 pb-1 rounded-tl-lg bg-white border-b-[1px] border-gray-400">
                <div className="flex items-center">
                    {newPolicy ? (
                        <h2 className="font-semibold">New Policy Form</h2>
                    ) : (
                        <h2 className="font-semibold">Edit Policy Form</h2>
                    )}
                </div>
                <svg onClick={() => setSelectedPolicyID(-1)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </div>
            {loading && (
                <div className="w-full h-full p-2 pt-10">
                    <FormLoading
                        currentState={loadingState}
                    />
                </div>
            )}
            {error === "success" && (
                <div className="w-full h-full p-2 pt-10">
                    <div className="w-full h-full bg-green-100 border-[2px] border-green-300 rounded-lg flex items-center justify-center">
                        <div className="text-center">
                            <h2 className="font-semibold text-green-800">Policy has been successfully submitted!</h2>
                            <h3 className="font-semibold text-sm italic mt-2 text-green-800 text-center">Refreshing page now...</h3>
                        </div>
                    </div>
                </div>
            )}
            {error !== "" && error !== "success" && !loading && (
                <div className="w-full h-full p-2 pt-10">
                    <div className="w-full h-full bg-red-100 border-[2px] border-red-300 rounded-lg ">
                        <div className="w-full h-10 flex justify-end pr-2">
                            <button onClick={() => setError("")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="w-full h-[calc(100%-40px)] flex items-center justify-center pb-10">
                            <div>
                                <h2 className="font-semibold text-red-800">{error}</h2>
                                <h2 className="font-semibold text-sm italic mt-2 text-red-800 text-center">Refresh the page and try again.</h2>
                            </div>
                        </div>
                    </div>
                </div>

            )}
            {error === "" && !loading && (
                <>
                    <div className=" mt-9 pl-3 pr-3 pt-0">
                        <h2 className="mt-10 text-black font-semibold text-sm">Name:</h2>
                        <input value={policyName} onChange={(e) => setPolicyName(e.target.value)} className="border-[1px] border-gray-400 rounded-md pl-1 pr-1 bg-gray-100 h-7" type="text"></input>
                        <h2 className="mt-2 text-black font-semibold text-sm">Energy Pricing:</h2>
                        <NewEnergyInput
                            energyObjects={energyObjects}
                            setEnergyObjects={setEnergyObjects}
                        />
                        <h2 className="mt-3 text-black font-semibold text-sm">Parking Pricing:</h2>
                        <NewParkingInput
                            parkingObjects={parkingObjects}
                            setParkingObjects={setParkingObjects}
                        />
                        <h2 className="mt-3 text-black font-semibold text-sm">Sleeping & Grace Fees:</h2>
                        {/* <SleepingInput
                            sleepingArray={sleepingArray}
                            setUpdatedSleepingArray={setUpdatedSleepingArray}
                        /> */}
                        <SleepingGraceInput
                            sleepingObjects={sleepingObjects}
                            setSleepingObjects={setSleepingObjects}
                        />
                    </div>
                    <div className="h-16 w-full flex items-end justify-between mb-2 pl-2 pr-2">
                        <button className="text-sm font-semibold bg-red-300 rounded-md text-black p-1 pl-2 pr-2" onClick={() => setShowConfirmDelete(true)}>Delete</button>
                        {selectedPolicyID === -2 ? (
                            <button className="text-sm font-semibold bg-primary rounded-md text-white p-1 pl-2 pr-2" onClick={() => submitOrSaveForm("submit")}>Submit</button>
                        ) : (
                            <button className="text-sm font-semibold bg-primary rounded-md text-white p-1 pl-2 pr-2" onClick={() => submitOrSaveForm("save")}>Save</button>
                        )}
                    </div>
                </>
            )}
            {showConfirmDelete && (
                createPortal(
                    <div className={`fixed z-[9999] right-0 left-0 top-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center`}>
                        <ConfirmDelete
                            setShowConfirmDelete={setShowConfirmDelete}
                            chargers={chargers}
                            title={"Delete Policy"}
                            name={policyName}
                            refreshData={refreshData}
                            passes={passes}
                            policies={policies}
                        />
                    </div>
                    , document.body
                )
            )}
        </div>
    )
}

export default PolicyForm