import { useEffect, useState } from "react"
import { sortAllRequestsByDateRequested, organizeUserCentricStatuses } from "./service"
import { Fragment } from "react"

import PendingSection from "./pendingComponents/pendingSection"
import UserSection from "./userComponents/userSection"
import TableTile from "./userComponents/tableTile"

function Table({
    userPassInfo,
    userPassInfoError,
    userPassInfoLoading,
    refreshData
}) {

    const [sortedRequests, setSortedRequests] = useState([])
    const [userRequestStatuses, setUserRequestStatuses] = useState(null)

    // 
    // Sort each request by most recent request_date, 
    // and in the future handle errors/loading states
    // 

    // useEffect(() => {
    //     if (userPassInfoError || userPassInfoLoading) return
    //     try {
    //         const sorted = sortAllRequestsByDateRequested(userPassInfo)
    //         const userCentric = organizeUserCentricStatuses(userPassInfo)
    //         console.log(userCentric)
    //         setSortedRequests(sorted)
    //         setUserRequestStatuses(userCentric)
    //     } catch (error) {
    //         console.error("Error sorting all requests via date.")
    //     }
    // }, [userPassInfo, userPassInfoError, userPassInfoLoading])


    // 
    // Pending-centric first
    // User-centric after
    // 


    return (
        <div className="h-[calc(100%-96px)] w-full bg-gray-100 overflow-y-scroll dashboardScrollBar">

            {/* 
                First map the pending requests (pending-centric)
            */}

            <PendingSection
                userPassInfo={userPassInfo}
                userPassInfoError={userPassInfoError}
                userPassInfoLoading={userPassInfoLoading}
                refreshData={refreshData}
            />

            {/* 
                Map all of the users based on most recent request date
            */}

            <UserSection
                userPassInfo={userPassInfo}
                userPassInfoError={userPassInfoError}
                userPassInfoLoading={userPassInfoLoading}
                refreshData={refreshData}
            />

        </div>
    )
}

export default Table