

function PermitSection({
    user
}) {
    
    console.log(user)
    
    return(
        <div className="col-span-2 w-full pb-5 mt-2 border-t-[1px] border-gray-400">
            {/* {user.permits && user.permits.length > 0 && user.permits.map((permit, index) => (
                <div key={index} className="h-full w-32 mr-3 bg-gray-500">
                 </div>   
            ))} */}
            {user.permits && user.permits.length > 0 && user.permits.map((permit, index) => (
                <div key={index} className="h-10 w-full border-b-[1px] border-gray-400">
                 </div>   
            ))}
        </div>
    )
}

export default PermitSection