import { Bar, Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { useState, useEffect, useRef } from "react";
import 'chart.js/auto';
import timeFunctions from "../../../helpers/timeFunctions";
import 'chartjs-adapter-date-fns';
import strings from "../../../helpers/strings";
import { useDispatch } from "react-redux";
import { changePercent } from "../../../redux/reducers/loadingSlice";
import { getMinMaxTimes } from "../../../helpers/componentHelpers/dashboardGraphs"

function HistoryGraph({
    graphDimensions,
    graphArray,
    title,
    chargersToShow,
    startingDateHistoryGraph,
    endingDateHistoryGraph
}) {

        // console.log(graphArray, title)

    const parentDivDimensions = {
        width: graphDimensions.width,
        height: graphDimensions.height,
    };

    const chartRef = useRef(null)
    const [minY, setMinY] = useState(0);
    const [maxY, setMaxY] = useState(250);
    const [chartData, setChartData] = useState({
        labels: ["0", "1"],
        datasets: [],
    })
    const dispatch = useDispatch()

    useEffect(() => {
        console.log(startingDateHistoryGraph)
        setChartOptions(prevOptions => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                x: {
                    ...prevOptions.scales.x,
                    min: startingDateHistoryGraph,
                    max: endingDateHistoryGraph
                }
            }
        }));
        if (chartRef.current) {
            chartRef.current.options.scales.y.max = maxY;
            chartRef.current.update();
        }
    }, [maxY, startingDateHistoryGraph, endingDateHistoryGraph])

    // console.log(graphArray)

    function parseCustomDateData(timeStr) {
        const timePart = timeStr.split('-')[0]; // Extract the time part before the '-'
        const date = new Date(timePart.replace(/\//g, '-')); // Convert to Date object
        return date.toISOString();
    }

    function mapDataForChart(dataArray) {
        return dataArray.map(sublist =>
            sublist.map(item => {
                if (item.time && item.value) {
                    return {
                        x: parseCustomDateData(item.time),
                        y: Number(item.value)
                    };
                }
                return null;
            }).filter(item => item !== null)
        );
    }

    useEffect(() => {
        // console.log(graphArray)
        try {
            const alterTimes = mapDataForChart(graphArray)
            // console.log(alterTimes)
            // const allTimes = alterTimes.flat()
            // console.log(allTimes)
            const datasets = alterTimes.map((dataset, index) => ({
                label: `Dataset ${index + 1}`,
                data: dataset.map(point => ({
                    x: point.x,
                    y: point.y
                })),
                pointRadius: 0,
                pointHitRadius: 20,
                borderWidth: 2.3,
                borderColor: strings.RGBGraphColors[index],
                fill: false,
                hidden: !chargersToShow.includes(index)
            }));
            // console.log(datasets)
            let tempMax = -Infinity
            const flatGraphArray = alterTimes.flat()
            flatGraphArray.forEach((time) => {
                let floatValue = parseFloat(time.y)
                if (floatValue > tempMax) {
                    tempMax = floatValue
                }
            })
            // setMaxY(Math.floor(tempMax + (Math.floor(.2 * tempMax))))
            setChartData(prevChartData => ({
                ...prevChartData,
                datasets: datasets,
            }));
            dispatch(changePercent({ loadPer: 100 }))
        } catch (error) {
            console.error("Error formatting 90 day history data for graph", error)
        }
    }, [graphArray, chargersToShow])

    const [chartOptions, setChartOptions] = useState({
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                type: "linear",
                position: "left",
                beginAtZero: false,
                min: minY,
                max: maxY,
                title: {
                    display: true,
                    text: title === "Cumulative Energy Consumption" ? "kWh" : "kWh",
                    font: {
                        size: 12,
                        weight: 400,
                        lineHeight: 0.5,
                    },
                    color: 'gray',
                    padding: 6,
                },
                ticks: {
                    padding: 5
                }
            },
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'M/dd/yy'
                    },
                    tooltipFormat: 'MMM d, h:mm a'
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                min: '',
                max: ''
            },
        },
        plugins: {
            legend: {
                display: false
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'y',
                },
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        return context[0].label;
                    },
                    label: function (context) {
                        return context.formattedValue;
                    }
                }
            },
        },
    });


    return (
        <div style={parentDivDimensions}>
            <Bar ref={chartRef} data={chartData} options={chartOptions} />
        </div>
    )
}

export default HistoryGraph;