import React from "react"

function StatsWidget({
    title,
    value,
    unit
}) {

    return(
        <div className="col-span-4 h-24 bg-white rounded-md shadow-md p-3">
            <h2 className="font-semibold text-lg text-gray-900">{title}</h2>
            <h3 className="text-3xl lg:text-4xl mt-[0px] font-bold text-blue-800">{value}</h3>
            {/* <h2 className="mt-[14px] text-sm text-gray-800">Global Rank: #328</h2> */}
        </div>
    )
}

export default StatsWidget