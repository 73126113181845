import { useState, useEffect } from "react"
import { createUserCentricStructure } from "../service"

import TableTile from "./tableTile"

function UserSection({
    userPassInfo,
    userPassInfoError,
    userPassInfoLoading,
    refreshData
}) {

    const [userCentricData, setUserCentricData] = useState(null)
    const [checkedUsers, setCheckedUsers] = useState([])

    useEffect(() => {
        if (userPassInfoError || userPassInfoLoading) return
        const userCentric = createUserCentricStructure(userPassInfo.data)
        setUserCentricData(userCentric)
    }, [userPassInfo])

    return (
        <>
            <div className="h-10 w-full flex items-center bg-gray-50 pl-3 pr-3 border-b-[1px] border-gray-300">
                <h2 className="text-sm font-semibold italic">All Users</h2>
            </div>
            {userCentricData && userCentricData.users.map((user, index) => (
                <TableTile
                    key={index}
                    user={user}
                    index={index}
                    checkedUsers={checkedUsers}
                    setCheckedUsers={setCheckedUsers}
                />
            ))}
        </>
    )
}

export default UserSection