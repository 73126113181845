import { useEffect, useState } from "react"
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"
import useSWR, { mutate } from "swr"
import axios from "axios";

import Header from "../globalComponents/header"
import ProfilePage from "./profilePage/profilePage"
import SettingsPage from "./settingsPage/settingsPage"
// import PartnersPage from "./partners/partnersPage";

function SettingsMain({
    location
}) {

    const [subPage, setSubPage] = useState("profile")

    // const token = Cookies.get('jwt_token')
    // const decodedToken = jwtDecode(token)
    // const userDataUrl = `https://ohdpdasapi.com:443/das/user/${decodedToken.sub.id}/details`
    // const allUsersUrl = `https://ohdpdasapi.com:443/das/user/${decodedToken.sub.id}/allusers`
    // const partnerDataUrl = `https://ohdpdasapi.com:443/das/user/${decodedToken.sub.id}/partners`

    // 
    // A generic axios fetch function for the useSWR hook to use
    // 

    // const genericFetcher = (url) => axios.get(url, {
    //     headers: { 'Authorization': `Bearer ${token}` }
    // }).then(res => res.data)

    // 
    // Fetch and cache user data, all users, and user partner data
    // 

    // const { data: userData, error: userDataError, isLoading: userDataLoading } = useSWR(
    //     [userDataUrl], () => genericFetcher(userDataUrl)
    // );
    // const { data: allUsers, error: allUsersError, isLoading: allUsersLoading } = useSWR(
    //     [allUsersUrl], () => genericFetcher(allUsersUrl)
    // );
    // const { data: partnerData, error: partnerDataError, isLoading: partnerDataLoading } = useSWR(
    //     [partnerDataUrl], () => genericFetcher(partnerDataUrl)
    // );

    // const refreshData = () => {
    //     console.log("called")
    //     mutate(userDataUrl)
    //     mutate(allUsersUrl)
    //     mutate(partnerDataUrl)
    // }
    
    // useEffect(() => {
    //     setSubPage(location)
    // }, [location])

    return (
        <>
            <Header />
            <div className="w-full overflow-x-hidden overflow-y-hidden h-[calc(100vh-56px)] flex justify-center pr-4 pl-4">
                <div className="w-[1000px] h-full grid grid-cols-12">
                    <div className="col-span-3 h-[calc(100vh-56px)] border-r-[1px] border-gray-400 pt-12 ">
                        <button onClick={() => setSubPage("profile")} className={`${subPage === "profile" ? "bg-blue-200 " : "hover:bg-gray-100"} w-full flex items-center rounded-md rounded-r-none p-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                            </svg>
                            <h2 className="text-sm ml-2">Profile</h2>
                        </button>
                        <button onClick={() => setSubPage("settings")} className={`${subPage === "settings" ? "bg-blue-200 " : "hover:bg-gray-100 "} mt-1 w-full flex items-center rounded-md rounded-r-none p-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                            <h2 className="text-sm ml-2">Settings</h2>
                        </button>
                    </div>
                    <div className="col-span-9 h-full">
                        {subPage === "profile" && (
                            <ProfilePage
                                // userData={userData}
                                // userDataLoading={userDataLoading}
                                // userDataError={userDataError}
                            />
                        )}
                        {subPage === "settings" && <SettingsPage />}
                        {/* {subPage === "partners" && (
                            <PartnersPage
                                allUsers={allUsers}
                                allUsersLoading={allUsersLoading}
                                allUsersError={allUsersError}
                                partnerData={partnerData}
                                partnerDataLoading={partnerDataLoading}
                                partnerDataError={partnerDataError}
                                refreshData={refreshData}
                            />
                        )} */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default SettingsMain