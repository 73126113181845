import { useState, useEffect } from "react"

function ProfilePage({
    userData,
    userDataLoading,
    userDataError
}) {

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [company, setCompany] = useState("")
    const [address, setAddress] = useState("")

    // useEffect(() => {
    //     if (userDataLoading || userDataError) return
    //     setFirstname(userData.first_name)
    //     setLastname(userData.last_name)
    //     setEmail(userData.username)
    //     setPhoneNumber(userData.phone_number)
    //     setCompany(userData.company)
    //     setAddress(userData.address)
    // }, [userData, userDataLoading, userDataError])

    return (
        <div className="col-span-9 pt-8 pl-8">
            <h1 className="font-semibold text-2xl">Profile</h1>
            <h2 className="text-lg font-semibold mt-4">Account</h2>
            <h3 className="mt-1 text-sm text-gray-700">Avatar</h3>
            <img alt="profile pic" src="/Grid_logo_mark.png" className="h-16 w-16 p-[6px] bg-gray-200 rounded-full border-[1px] border-gray-400 mt-[2px]" />
            <h3 className="mt-3 text-sm text-gray-700">Full Name</h3>
            <div className="flex mt-[2px]">
                <input value={firstname} onChange={(e) => setFirstname(e.target.value)} maxLength={30} placeholder="firstname" className="w-full h-7 rounded-[4px] text-sm pl-2 bg-white border-[1px] border-gray-400"></input>
                <input value={lastname} onChange={(e) => setLastname(e.target.value)} maxLength={30} placeholder="lastname" className="w-full h-7 rounded-[4px] text-sm pl-2 bg-white border-[1px] border-gray-400 ml-1"></input>
            </div>
            <h3 className="mt-3 text-sm text-gray-700">Email</h3>
            <input value={email} onChange={(e) => setEmail(e.target.value)} maxLength={60} placeholder="" className="w-full h-7 rounded-[4px] text-sm pl-2 bg-white border-[1px] border-gray-400"></input>
            <h3 className="mt-3 text-sm text-gray-700">Phone Number</h3>
            <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} maxLength={15}  placeholder="" className="w-full h-7 rounded-[4px] text-sm pl-2 bg-white border-[1px] border-gray-400"></input>
            <h3 className="mt-3 text-sm text-gray-700">Address</h3>
            <input value={address} onChange={(e) => setAddress(e.target.value)} maxLength={60} placeholder="" className="w-full h-7 rounded-[4px] text-sm pl-2 bg-white border-[1px] border-gray-400"></input>
            <h3 className="mt-3 text-sm text-gray-700">Password</h3>
            <button className="text-xs pl-2 pr-2 p-1 font-semibold rounded-md text-white bg-gray-700">Change Password</button>
            <h2 className="text-lg font-semibold mt-4">Other</h2>
            <h3 className="mt-1 text-sm text-gray-700">Company</h3>
            <input value={company} onChange={(e) => setCompany(e.target.value)} maxLength={60} placeholder="" className="w-full h-7 rounded-[4px] text-sm pl-2 bg-white border-[1px] border-gray-400"></input>
            <div className="mt-10 w-full flex justify-end">
                <button className="text-sm pl-3 pr-3 p-2 font-semibold rounded-md text-white bg-primary">Save Changes</button>
            </div>
        </div>
    )
}

export default ProfilePage