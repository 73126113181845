import { useState, useEffect, Fragment } from "react";
import { userPassUpdate } from "../../../helpers/apis";

import PendingTableTile from "./pendingTableTile";

function PendingSection({
    userPassInfo,
    userPassInfoError,
    userPassInfoLoading,
    refreshData
}) {

    const [checkedRequests, setCheckedRequests] = useState([])
    const [allPendingRequestIds, setAllPendingRequestIds] = useState([])

    // 
    // Get all of the pending ids for the "select all" button functionality 
    // 

    useEffect(() => {
        if (userPassInfoError || userPassInfoLoading) return;
        const pendingIds = Object.entries(userPassInfo.data).flatMap(([permit, items]) =>
            items
                .filter((item) => item.status !== "accepted" && item.status !== "denied")
                .map((item) => item.request_id)
        );
        setAllPendingRequestIds(pendingIds)
    }, [userPassInfo, refreshData]);

    // 
    // This handles the accept selected and deny selected buttons, and changes the status on all selected requests 
    //

    async function updateAllRequests(status) {
        try {
            const promises = checkedRequests.map((request_id) =>
                userPassUpdate(request_id, "0000-00-00 00:00:00", status)
            );
            const responses = await Promise.all(promises);
            setCheckedRequests([])
            console.log("Responses:", responses);
            refreshData()
            setTimeout(() => {
                refreshData()
            }, 700)
        } catch (error) {
            console.error("Error updating request on permit", error);
        }
    }


    return (
        <div>
            <div className="h-10 w-full flex items-center bg-gray-50 pl-3 pr-3 border-b-[1px] border-gray-300 justify-between">
                <h2 className="text-sm text-yellow-600 font-semibold italic">Pending Requests</h2>
                <div className="flex items-center">
                    {checkedRequests.length === allPendingRequestIds.length ? (
                        <button className="text-sm italic" onClick={() => setCheckedRequests([])}>
                            deselect all
                        </button>
                    ) : (
                        <button className="text-sm italic" onClick={() => setCheckedRequests(allPendingRequestIds)}>
                            select all
                        </button>
                    )}
                    <button onClick={() => updateAllRequests("accepted")} className="ml-4 text-xs font-semibold pl-[6px] pr-[6px] p-[2px] rounded-md text-green-700 bg-green-50 border-[1px] border-green-700">
                        Accept Selected
                    </button>
                    <button onClick={() => updateAllRequests("denied")} className="ml-2 text-xs font-semibold pl-[6px] pr-[6px] p-[2px] rounded-md text-red-700 bg-red-50 border-[1px] border-red-700">
                        Deny Selected
                    </button>
                </div>
            </div>

            {!userPassInfoError && !userPassInfoLoading &&
                Object.entries(userPassInfo.data).map(([permit, data], index) => {
                    let pendingRequests = [];
                    data.forEach((request) => {
                        if (request.status !== "denied" && request.status !== "accepted") {
                            pendingRequests.push(request);
                        }
                    });
                    return pendingRequests.length > 0 ? (
                        <div key={index} className="w-full">
                            {pendingRequests.map((request, i) => (
                                <PendingTableTile
                                    permit={permit}
                                    request={request}
                                    key={i}
                                    checkedRequests={checkedRequests}
                                    setCheckedRequests={setCheckedRequests}
                                    refreshData={refreshData}
                                />
                            ))}
                        </div>
                    ) : (
                        <Fragment key={index} />
                    );
                })
            }
        </div>
    )
}

export default PendingSection