import { useState } from "react"
import DatePicker from "react-datepicker"
import { userPassUpdate } from "../../../../../../helpers/apis"

import Request from "./request"

function RequestSubSection({
    type,
    requests,
    refreshData
}) {

    const [quickChange, setQuickChange] = useState(false)
    const [status, setStatus] = useState(type)
    const [date, setDate] = useState("")

    const [savedSuccess, setSavedSuccess] = useState("")

    // 
    // Update all requests in this sub section
    // 

    async function updateAllRequests() {
        setSavedSuccess("loading");
        try {
            const promises = requests.map((request) =>
                userPassUpdate(request.request_id, date, status)
            );
            const responses = await Promise.all(promises);
            console.log("Responses:", responses);
            setSavedSuccess("success");
            setTimeout(() => {
                refreshData()
            }, 700)
        } catch (error) {
            console.error("Error updating request on permit", error);
            setSavedSuccess("error");
        }
    }



    return (
        <div className="p-2 mt-1 rounded-md bg-white border-[1px] border-gray-300">
            <div className="flex items-center justify-between">
                {type === "pending" && (
                    <h3 className="text-sm font-bold mb-1 text-yellow-600">Pending</h3>
                )}
                {type === "accepted" && (
                    <h3 className="text-sm font-bold mb-1 text-green-600">Accepted</h3>
                )}
                {type === "denied" && (
                    <h3 className="text-sm font-bold mb-1 text-red-600">Denied</h3>
                )}
                <button onClick={() => setQuickChange(!quickChange)} className="flex items-center">
                    {!quickChange ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                            <path fillRule="evenodd" d="M7.84 1.804A1 1 0 0 1 8.82 1h2.36a1 1 0 0 1 .98.804l.331 1.652a6.993 6.993 0 0 1 1.929 1.115l1.598-.54a1 1 0 0 1 1.186.447l1.18 2.044a1 1 0 0 1-.205 1.251l-1.267 1.113a7.047 7.047 0 0 1 0 2.228l1.267 1.113a1 1 0 0 1 .206 1.25l-1.18 2.045a1 1 0 0 1-1.187.447l-1.598-.54a6.993 6.993 0 0 1-1.929 1.115l-.33 1.652a1 1 0 0 1-.98.804H8.82a1 1 0 0 1-.98-.804l-.331-1.652a6.993 6.993 0 0 1-1.929-1.115l-1.598.54a1 1 0 0 1-1.186-.447l-1.18-2.044a1 1 0 0 1 .205-1.251l1.267-1.114a7.05 7.05 0 0 1 0-2.227L1.821 7.773a1 1 0 0 1-.206-1.25l1.18-2.045a1 1 0 0 1 1.187-.447l1.598.54A6.992 6.992 0 0 1 7.51 3.456l.33-1.652ZM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                        </svg>
                    )}
                </button>
            </div>
            {quickChange && (
                <div className="pb-2 w-full">
                    <h2 className="text-sm font-semibold">These inputs change the values for all requests in this section.</h2>
                    <div className="flex justify-between items-end">
                        <div className="flex mt-1">
                            <div className="">
                                <h3 className="text-xs text-gray-800 mb-[2px]">Status:</h3>
                                <select value={status} onChange={(e) => { setStatus(e.target.value) }} className=" h-7 w-24 pl-2 pr-2 text-sm bg-white border-[1px] border-gray-400 rounded-full">
                                    <option>accepted</option>
                                    <option>denied</option>
                                    <option>pending</option>
                                </select>
                            </div>
                            <div className="ml-4">
                                <h4 className="text-xs text-gray-700 mb-[2px]">Expiration Date:</h4>
                                <DatePicker
                                    selected={date}
                                    onChange={(date) => { setDate(date) }}
                                    dateFormat="yyyy-MM-dd"
                                    className="bg-white h-7 w-24 pl-2 pr-2 text-sm border-[1px] rounded-full border-gray-400"
                                />
                            </div>
                        </div>
                        <div className="flex items-center mt-4 mr-2">
                            {savedSuccess === "success" && (
                                <>
                                    <h4 className="text-sm italic text-green-600 mr-1 font-semibold">request saved</h4>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-green-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                    </svg>
                                </>
                            )}
                            {savedSuccess === "loading" && (
                                <h4 className="text-sm italic text-blue-600 mr-1 font-semibold">loading...</h4>
                            )}
                            {savedSuccess === "error" && (
                                <>
                                    <h4 className="text-sm italic text-red-600 mr-1 font-semibold">error updating request</h4>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-red-600 mt-[2px]">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                                    </svg>

                                </>
                            )}
                            {savedSuccess === "" && (
                                <button onClick={() => updateAllRequests()} className="text-xs pl-1 pr-1 p-[2px] -mr-2 bg-blue-100 border-[1px] border-secondary rounded-md h-6 text-secondary font-semibold">Submit</button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {requests.map((request, index) => (
                <Request
                    key={index}
                    request={request}
                    refreshData={refreshData}
                />
            ))}
        </div>
    )
}

export default RequestSubSection