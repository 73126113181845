import React, { useState, useEffect } from "react"
import { updateCarValues, deleteUserVehicle } from "../../helpers/apis"

import AddVehicle from "./addVehicle"

function UsersCars({
    userCarInfo,
    decodedToken,
    refreshData
}) {

    // -2 means nothing is selected, -1 means the selected vehicle is expanded, other index mean the mapped cars
    const [expandIndex, setExpandIndex] = useState(-2)
    const [addVehicleToggle, setAddVehicleToggle] = useState(false)

    const [nickname, setNickname] = useState("")
    const [license, setLicense] = useState("")
    const [capacity, setCapacity] = useState(null)
    const [vehicle, setVehicle] = useState("")

    const [apiSuccess, setApiSuccess] = useState(false)

    // Enable the submit button if the car data changes
    useEffect(() => {
        setTimeout(() => {
            setApiSuccess(false)
        }, 1000)
    }, [userCarInfo])

    // Change the input values when the user selects a new car
    useEffect(() => {
        if (expandIndex === -2) return
        setApiSuccess(false)
        if (expandIndex === -1) {
            setNickname(userCarInfo.data.selected.nickname)
            setLicense(userCarInfo.data.selected.license)
            setCapacity(userCarInfo.data.selected.capacity)
            setVehicle(userCarInfo.data.selected.model)
        } else {
            setNickname(userCarInfo.data.other[expandIndex].nickname)
            setLicense(userCarInfo.data.other[expandIndex].license)
            setCapacity(userCarInfo.data.other[expandIndex].capacity)
            setVehicle(userCarInfo.data.other[expandIndex].model)
        }
    }, [expandIndex])

    // Call the put API and update the values
    async function updateCarValuesSubmit() {
        if (expandIndex === -2) return
        const body = {
            capacity: capacity ? capacity : 0,
            license: license,
            nickname: nickname,
            username: decodedToken.username,
            vehicle: vehicle
        }
        try {
            const response = await updateCarValues(body)
            if (response.status === 200) {
                setApiSuccess(true)
                refreshData()
            }
        } catch (error) {
            console.error("Error updating car values")
        }
    }

    // Delete the selected vehicle and refetch the users car list
    async function deleteVehicle() {
        try {
            const response = await deleteUserVehicle(decodedToken.username, vehicle)
            if(response.status === 200) {
                refreshData()
                setExpandIndex(-2)
            }
        } catch (error) {
            console.error("Error deleting user vehicle", error)
        }
    }

    return (
        <>
            {addVehicleToggle ? (
                <AddVehicle 
                    decodedToken={decodedToken}
                    refreshData={refreshData}
                    setAddVehicleToggle={setAddVehicleToggle}
                />
            ) : (
                <button onClick={() => setAddVehicleToggle(true)} className="mt-3 pl-3 pr-3 p-2 bg-primary text-white font-semibold rounded-lg w-full">
                    Add Vehicle
                </button>
            )}
            <div className="rounded-md w-full border-[1px] border-gray-300 bg-white p-2 pl-3 pr-3 mt-2">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <h2 className="text-black text-lg font-semibold ">{userCarInfo?.data.selected.nickname}</h2>
                        <h3 className="text-secondary text-sm font-regular ml-2 mt-[2px]">{userCarInfo?.data.selected.model}</h3>
                    </div>
                    {expandIndex === -1 ? (
                        <button onClick={() => setExpandIndex(-2)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-secondary">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    ) : (
                        <button onClick={() => setExpandIndex(-1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-secondary">
                                <path fillRule="evenodd" d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 0 1-.517.608 7.45 7.45 0 0 0-.478.198.798.798 0 0 1-.796-.064l-.453-.324a1.875 1.875 0 0 0-2.416.2l-.243.243a1.875 1.875 0 0 0-.2 2.416l.324.453a.798.798 0 0 1 .064.796 7.448 7.448 0 0 0-.198.478.798.798 0 0 1-.608.517l-.55.092a1.875 1.875 0 0 0-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 0 1-.064.796l-.324.453a1.875 1.875 0 0 0 .2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 0 1 .796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 0 1 .517-.608 7.52 7.52 0 0 0 .478-.198.798.798 0 0 1 .796.064l.453.324a1.875 1.875 0 0 0 2.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 0 1-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 0 0 1.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 0 1-.608-.517 7.507 7.507 0 0 0-.198-.478.798.798 0 0 1 .064-.796l.324-.453a1.875 1.875 0 0 0-.2-2.416l-.243-.243a1.875 1.875 0 0 0-2.416-.2l-.453.324a.798.798 0 0 1-.796.064 7.462 7.462 0 0 0-.478-.198.798.798 0 0 1-.517-.608l-.091-.55a1.875 1.875 0 0 0-1.85-1.566h-.344ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" clipRule="evenodd" />
                            </svg>
                        </button>
                    )}
                </div>
                {expandIndex === -1 && (
                    <div className="mt-1 pb-1">
                        <h4 className="text-gray-700 text-xs pl-1 pr-1">nickname:</h4>
                        <input value={nickname} onChange={(e) => setNickname(e.target.value)} type="text" className="w-full h-7 bg-gray-100 rounded-md border-[1px] border-gray-300 text-sm pl-2 pr-2"></input>
                        <h4 className="text-gray-700 text-xs pl-1 pr-1">license plate:</h4>
                        <input value={license} onChange={(e) => setLicense(e.target.value)} type="text" className="w-full h-7 bg-gray-100 rounded-md border-[1px] border-gray-300 text-sm pl-2 pr-2"></input>
                        <div className="w-full flex justify-end">
                            {apiSuccess ? (
                                <button disabled={true} className="text-xs bg-green-700 pl-2 pr-2 p-1 text-textWhite font-semibold rounded-md mt-3">
                                    Success! Loading...
                                </button>
                            ) : (
                                <button onClick={() => updateCarValuesSubmit()} className="text-xs bg-secondary pl-2 pr-2 p-1 text-textWhite font-semibold rounded-md mt-3">
                                    Save Changes
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {userCarInfo?.data.other.map((car, index) => (
                <div key={index} className="rounded-md w-full border-[1px] border-gray-300 bg-white mt-2 p-2 pl-3 pr-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <h2 className="text- text-lg font-semibold ">{car.nickname}</h2>
                            <h3 className="text-secondary text-sm font-regular ml-2 mt-[2px]">{car.model}</h3>
                        </div>
                        {expandIndex === index ? (
                            <button onClick={() => setExpandIndex(-2)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-secondary">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </button>
                        ) : (
                            <button onClick={() => setExpandIndex(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-secondary">
                                    <path fillRule="evenodd" d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 0 1-.517.608 7.45 7.45 0 0 0-.478.198.798.798 0 0 1-.796-.064l-.453-.324a1.875 1.875 0 0 0-2.416.2l-.243.243a1.875 1.875 0 0 0-.2 2.416l.324.453a.798.798 0 0 1 .064.796 7.448 7.448 0 0 0-.198.478.798.798 0 0 1-.608.517l-.55.092a1.875 1.875 0 0 0-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 0 1-.064.796l-.324.453a1.875 1.875 0 0 0 .2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 0 1 .796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 0 1 .517-.608 7.52 7.52 0 0 0 .478-.198.798.798 0 0 1 .796.064l.453.324a1.875 1.875 0 0 0 2.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 0 1-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 0 0 1.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 0 1-.608-.517 7.507 7.507 0 0 0-.198-.478.798.798 0 0 1 .064-.796l.324-.453a1.875 1.875 0 0 0-.2-2.416l-.243-.243a1.875 1.875 0 0 0-2.416-.2l-.453.324a.798.798 0 0 1-.796.064 7.462 7.462 0 0 0-.478-.198.798.798 0 0 1-.517-.608l-.091-.55a1.875 1.875 0 0 0-1.85-1.566h-.344ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" clipRule="evenodd" />
                                </svg>
                            </button>
                        )}
                    </div>
                    {expandIndex === index && (
                        <div className="mt-1 pb-1">
                            <h4 className="text-gray-700 text-xs pl-1 pr-1">nickname:</h4>
                            <input value={nickname} onChange={(e) => setNickname(e.target.value)} type="text" className="w-full h-7 bg-gray-100 rounded-md border-[1px] border-gray-300 text-sm pl-2 pr-2"></input>
                            <h4 className="text-gray-700 text-xs pl-1 pr-1">license plate:</h4>
                            <input value={license} onChange={(e) => setLicense(e.target.value)} type="text" className="w-full h-7 bg-gray-100 rounded-md border-[1px] border-gray-300 text-sm pl-2 pr-2"></input>
                            <div className="w-full flex justify-between items-center">
                                <button onClick={() => deleteVehicle()} className="text-sm text-red-700 mt-3">
                                    delete vehicle
                                </button>
                                {apiSuccess ? (
                                    <button disabled={true} className="text-xs bg-green-700 pl-2 pr-2 p-1 text-textWhite font-semibold rounded-md mt-3">
                                        Success!
                                    </button>
                                ) : (
                                    <button onClick={() => updateCarValuesSubmit()} className="text-xs bg-secondary pl-2 pr-2 p-1 text-textWhite font-semibold rounded-md mt-3">
                                        Save Changes
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </>
    )
}

export default UsersCars