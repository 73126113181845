
const sortAllRequestsByDateRequested = (userPassInfo) => {
    const combinedArray = Object.values(userPassInfo.data).flat();
    combinedArray.sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
    return combinedArray;
}

const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${formattedHours}:${minutes} ${ampm}, ${month}/${day}/${year}`;
}

const organizeUserCentricStatuses = (userPassInfo) => {
    const permits = userPassInfo.data;
    const users = {};

    // Collect all users and their statuses
    Object.keys(permits).forEach((permit) => {
        permits[permit].forEach((entry) => {
            if (!users[entry.username]) {
                users[entry.username] = {};
            }
            users[entry.username][permit] = entry.status; // Add status for this permit
        });
    });

    // Ensure all users have all permits listed, with "not applied" for missing ones
    const allPermits = Object.keys(permits);
    Object.keys(users).forEach((username) => {
        allPermits.forEach((permit) => {
            if (!users[username][permit]) {
                users[username][permit] = "not applied";
            }
        });
    });

    return users;
}

const createUserCentricStructure = (data) => {
    const userMap = {}; 
    Object.entries(data).forEach(([permit, requests]) => {
        requests.forEach((request) => {
            const username = request.username;

            // If the user isn't already in the map, add them
            if (!userMap[username]) {
                userMap[username] = {
                    username: username,
                    permits: []
                };
            }
            userMap[username].permits.push({
                permit: permit,
                request_id: request.request_id || null,
                status: request.status || null,
                request_date: request.request_date || null,
                response: request.response || null,
                expiration_date: request.expiration_date || null
            });
        });
    });

    // Convert the user map into an array of user objects
    return { users: Object.values(userMap) };
}


const dummyNames = [
    "Kyler Murray",
    "Desmond Ridder",
    "Lamar Jackson",
    "Josh Allen",
    "Bryce Young",
    "Justin Fields",
    "Anthony Richardson",
    "Jared Goff",
    "Joe Burrow",
    "Deshaun Watson",
    "Dak Prescott",
    "Russell Wilson",
    "Jordan Love",
    "C.J. Stroud",
    "Trevor Lawrence",
    "Patrick Mahomes",
    "Tua Tagovailoa",
    "Kirk Cousins",
    "Mac Jones",
    "Derek Carr",
    "Daniel Jones",
    "Zach Wilson",
    "Jalen Hurts",
    "Kenny Pickett",
    "Brock Purdy",
    "Geno Smith",
    "Baker Mayfield",
    "Ryan Tannehill",
    "Sam Howell",
    "Justin Herbert"
]


module.exports = {
    sortAllRequestsByDateRequested,
    dummyNames,
    formatTimestamp,
    organizeUserCentricStatuses,
    createUserCentricStructure
}