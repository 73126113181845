import { PolicyFormTimes } from "../helpers/strings";
import { CalenderInputDays, abbreviatedDayNames } from "../helpers/strings";

function AllDayInputs({
    day,
    selectedDayData,
    handleClick,
    handleInput,
    shiftSelectedIndexes
}) {

    return (
        <>
            {/* <button className="text-xs mt-3 ml-4 font-semibold pl-1 pr-1 p-[2px] bg-blue-100 text-secondary border-[1px] border-secondary rounded-md">
                Use Template
            </button>
            <div className="w-full h-8 bg-blue-100 border-[1px] border-secondary rounded-md flex items-center justify-between pl-6 pr-6">
                {CalenderInputDays.map((day, index) => (
                    <button key={index} className="text-sm font-semibold">{abbreviatedDayNames[day]}</button>
                ))}
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div> */}
            <div className="rounded-lg flex grid grid-cols-8 gap-y-3 gap-x-3 p-3 pr-4">
                {selectedDayData?.map((time, index) => (
                    <div
                        key={index}
                        onClick={(event) => handleClick(index, event, day)}
                        className={`flex flex-col col-span-1 items-center justify-center w-full ml-1 ${shiftSelectedIndexes.includes(index) ? "bg-blue-200" : ""}`}
                        style={{ userSelect: 'none' }}
                    >
                        <h3 className="text-xs" style={{ userSelect: 'none' }}>{PolicyFormTimes[index]}</h3>
                        <input
                            type="number"
                            value={selectedDayData[index]}
                            className="w-10 text-sm text-center h-6 rounded-[4px] border-[1px] border-gray-400 bg-gray-100"
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            onChange={(event) => {
                                event.stopPropagation();
                                handleInput(index, event.target.value);
                            }}
                            style={{ userSelect: 'none' }}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default AllDayInputs