

function FlatFee({
    day,
    parkingObjects,
    handleFlatFeeInput
}) {

    const selectedDayData = parkingObjects.find(item => item.weekday === day)?.parking

    return (
        <>
            <h3 className="mt-3 text-sm font">One time parking fee upon starting charge:</h3>
            <div className="h-8 w-full flex items-center pb-2 mt-1">
                <div className="flex items-center border-gray-400 border-[1px] rounded-[4px] h-6 bg-gray-100 pr-2">
                    <h2 className="ml-[8px] text-sm">$</h2>
                    <input onChange={(e) => handleFlatFeeInput(e.target.value)} value={selectedDayData[0]} type="number" className=" w-10 pl-1 pr-1 h-6 border-[1px] border-gray-400 ml-[3px] " ></input>
                </div>
            </div>
        </>
    )
}

export default FlatFee