
import { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import { userPassUpdate } from "../../../../../../helpers/apis"
import { debounce } from "lodash"

function Request({
    request,
    refreshData
}) {

    const [expandTile, setExpandTile] = useState(false)
    const [date, setDate] = useState("")
    const [status, setStatus] = useState("pending")

    const [savedSuccess, setSavedSuccess] = useState("")

    // 
    // Set status and date in state
    // 

    useEffect(() => {
        if (!request) return
        if (request.status === "accepted") { setStatus("accepted") }
        if (request.status === "denied") { setStatus("denied") }
        if (request.status !== "accepted" && request.status !== "denied") { setStatus("pending") }

        if (request.expiration_date === "0000-00-00 00:00:00") return
        setDate(request.expiration_date);
    }, [request]);

    // 
    // Call the API to update the request
    // 

    async function updatePass(date, status) {
        setSavedSuccess("loading")
        try {
            const response = await userPassUpdate(request.request_id, date, status)
            console.log(response)
            if (response.status === 200) {
                setSavedSuccess("success")
                setTimeout(() => {
                    refreshData()
                }, 1000)
            }
        } catch (error) {
            console.error("Error updating request on permit", error)
            setSavedSuccess("error")
        }
    }

    // 
    // This allows the form to update automatically without needing to press a submit button.
    // Gives users a 700ms delay from calling the function in case they pressed the wrong thing
    // 

    const callUpdatePass = debounce((input, inputType) => {
        if(inputType === "date") {
            updatePass(input, status)
        }
        if(inputType === "status") {
            updatePass(date, input)
        }
    }, 700)

    return (

        <div className="p-[2px] pl-0 pr-0 border-t-[1px] border-b-[1px] -mt-[1px] border-gray-300">
            <div className="flex items-center justify-between h-5">
                <div className="flex items-center">
                    <p className="text-sm">{request.username},</p>
                    <p className="text-xs ml-3"> {request.response}</p>
                </div>
                <button onClick={() => setExpandTile(!expandTile)}>
                    {expandTile ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 mt-[2px]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 mt-[2px]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25" />
                        </svg>
                    )}
                </button>
            </div>
            {expandTile && (
                <div className="pb-1 w-full mt-1 flex justify-between">
                    <div className="flex">
                        <div className="mr-4">
                            <h4 className="text-xs text-gray-700 mb-[2px]">Status:</h4>
                            <select value={status} onChange={(e) => {setStatus(e.target.value); callUpdatePass(e.target.value, "status")}} className=" h-7 w-24 pl-2 pr-2 text-sm bg-white border-[1px] border-gray-400 rounded-full">
                                <option>accepted</option>
                                <option>denied</option>
                                <option>pending</option>
                            </select>
                        </div>
                        <div>
                            <h4 className="text-xs text-gray-700 mb-[2px]">Expiration Date:</h4>
                            <DatePicker
                                selected={date}
                                onChange={(date) => {setDate(date); callUpdatePass(date, "date")}}
                                dateFormat="yyyy-MM-dd"
                                className="bg-white h-7 w-24 pl-2 pr-2 text-sm border-[1px] rounded-full border-gray-400"
                            />
                        </div>
                    </div>
                    <div className="flex items-center mt-4 mr-2">
                        {savedSuccess === "success" && (
                            <>
                                <h4 className="text-sm italic text-green-600 mr-1 font-semibold">request saved</h4>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-green-600">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                </svg>
                            </>
                        )}
                        {savedSuccess === "loading" && (
                            <h4 className="text-sm italic text-blue-600 mr-1 font-semibold">loading...</h4>
                        )}
                        {savedSuccess === "error" && (
                            <>
                                <h4 className="text-sm italic text-red-600 mr-1 font-semibold">error updating request</h4>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-red-600 mt-[2px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                                </svg>

                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Request