import React, { useEffect, useState } from "react";
import Cookies from "js-cookie"
import { genericSWRFetcher } from "../../helpers/apis";
import { jwtDecode } from "jwt-decode";
import useSWR, { mutate } from "swr";

import UsersCars from "./usersCars";
import EditProfile from "./editProfile";

function UserInfoRank() {

    const [profileSettingsToggle, setProfileSettingsToggle] = useState(false)
    const decodedToken = jwtDecode(Cookies.get("jwt_token_intellicharge"))
    const userInfoUrl = `https://intellichargemobile.com:443/user/information?username=${decodedToken.username}`
    const userCarInfoUrl = `https://www.intellichargemobile.com:443/vehiclesettings/userEVList?username=${decodedToken.username}`

    const { data: userInfo, error: userInfoError, isLoading: userInfoLoading } = useSWR(
        userInfoUrl, () => genericSWRFetcher(userInfoUrl),
        { refreshInterval: 1200000 }
    );
    const { data: userCarInfo, error: userCarInfoError, isLoading: userCarInfoLoading } = useSWR(
        userCarInfoUrl, () => genericSWRFetcher(userCarInfoUrl),
        { refreshInterval: 1200000 }
    );

    const refreshData = () => {
        mutate(userCarInfoUrl)
        mutate(userInfoUrl)
    }

    return (
        <div className="w-full bg-white rounded-md shadow-md p-3">
            <div className="w-full">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <h2 className="text-xl xl:text-2xl font-bold">{decodedToken?.username}</h2>
                        <img alt="userRankPicture" src="/greenLeaf.png" className="hidden lg:h-8 ml-2"></img>
                    </div>
                    {profileSettingsToggle ? (
                        <button onClick={() => setProfileSettingsToggle(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-gray-700">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    ) : (
                        <button onClick={() => { setProfileSettingsToggle(true) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-gray-700">
                                <path fillRule="evenodd" d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 0 1-.517.608 7.45 7.45 0 0 0-.478.198.798.798 0 0 1-.796-.064l-.453-.324a1.875 1.875 0 0 0-2.416.2l-.243.243a1.875 1.875 0 0 0-.2 2.416l.324.453a.798.798 0 0 1 .064.796 7.448 7.448 0 0 0-.198.478.798.798 0 0 1-.608.517l-.55.092a1.875 1.875 0 0 0-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 0 1-.064.796l-.324.453a1.875 1.875 0 0 0 .2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 0 1 .796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 0 1 .517-.608 7.52 7.52 0 0 0 .478-.198.798.798 0 0 1 .796.064l.453.324a1.875 1.875 0 0 0 2.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 0 1-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 0 0 1.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 0 1-.608-.517 7.507 7.507 0 0 0-.198-.478.798.798 0 0 1 .064-.796l.324-.453a1.875 1.875 0 0 0-.2-2.416l-.243-.243a1.875 1.875 0 0 0-2.416-.2l-.453.324a.798.798 0 0 1-.796.064 7.462 7.462 0 0 0-.478-.198.798.798 0 0 1-.517-.608l-.091-.55a1.875 1.875 0 0 0-1.85-1.566h-.344ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" clipRule="evenodd" />
                            </svg>
                        </button>
                    )}
                </div>
                <h2>{userInfo?.data?.first_name} {userInfo?.data?.last_name}</h2>
                {/* <h2 className="mt-2 text-md font-bold">Level <span className="font-bold">6</span></h2>
                <div className="w-full h-3 bg-gray-200 rounded-full mt-0">
                    <div className="w-[72%] h-full bg-primary rounded-full"></div>
                </div> */}
                {profileSettingsToggle ? (
                    <EditProfile
                        userInfo={userInfo}
                        refreshData={refreshData}
                        username={decodedToken.username}
                    />
                ) : (
                    <>
                        {!userCarInfoLoading && !userCarInfoError && (
                            <UsersCars
                                userCarInfo={userCarInfo}
                                decodedToken={decodedToken}
                                refreshData={refreshData}
                            />
                        )}
                    </>
                )}
            </div>
        </div >
    )
}

export default UserInfoRank