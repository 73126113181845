
import RequestSubSection from "./requestsComponents/requestsSubSection"

function RequestsContainer({
    index,
    selectedPermits,
    pendingRequests,
    acceptedRequests,
    deniedRequests,
    refreshData
}) {

    return (
        <div className="p-2 border-b-[2px] border-gray-400" >
            <h2 className="font-semibold text-md ml-2">{selectedPermits[index]}</h2>
            {pendingRequests.length !== 0 && (
                <RequestSubSection
                    type={"pending"}
                    requests={pendingRequests}
                    refreshData={refreshData}
                />
            )}
            {acceptedRequests.length !== 0 && (
                <RequestSubSection
                    type={"accepted"}
                    requests={acceptedRequests}
                    refreshData={refreshData}
                />
            )}
            {deniedRequests.length !== 0 && (
                <RequestSubSection
                    type={"denied"}
                    requests={deniedRequests}
                    refreshData={refreshData}
                />
            )}
        </div>
    )
}

export default RequestsContainer