import { useState, useEffect } from "react";
import { chargerDataFetcher } from "../helpers/apis"
import { combineChargerData, getAllChargerStatuses, getMaxPowerAmps, getNumberOfRequests } from "./dashboardService";
import useSWR from "swr";
import Cookies from 'js-cookie'
import { useSelector } from "react-redux";
import { getLoadPercent } from "../redux/selectors";
import { createPortal } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";

import Header from "../globalComponents/header";
import MapContainer from "../globalComponents/map/mapContainer";
import DashboardTable from "./tableComponents/dashboardTable";
import DashboardGraphs from "./graphComponents/dashboardGraphs";
import InitialLoad from "../globalComponents/initialLoad";
import TableFallbackError from "./tableFallbackError";

function MonitorDashboard() {

    const [userChargerData, setUserChargerData] = useState([])
    const [chargerDataArray, setChargerDataArray] = useState([])
    const [allChargerStatuses, setAllChargerStatuses] = useState({})
    const [maxAmps, setMaxAmps] = useState(0)
    const [requestsNum, setRequestsNum] = useState(0)
    const [loading, setLoading] = useState(false)
    const [clusterNames, setClusterNames] = useState([])
    const [simpleChargers, setSimpleChargers] = useState([])
    const [formalLotNames, setFormalLotNames] = useState({})
    const { finished } = useSelector(getLoadPercent)
    const chargerID = Cookies.get('charger_ID')

    // This useSWR hook is quite useful for regularly fetching data,
    // but causes the graphs to rerender every minute, which resets 
    // the state for which chargers have been selected. 
    // !!! I'm going to use this hook for the table and map only. !!!
    const { data: fetchedChargerData, error: userDataError, isLoading: userDataLoading } = useSWR(
        [`https://gmsapi1communication.com:443/dashData?chargerID=${chargerID}`],
        chargerDataFetcher,
        { refreshInterval: 120000 } // Refresh every 2 minutes
    );

    // 
    // This useEffect just ensures that the fetchedChargerData becomes 
    // mutable. Will be useful for error handling in the future
    // 


    useEffect(() => {
        if (fetchedChargerData) {
            setUserChargerData(fetchedChargerData);
            let formalLotObject = {};
            for (const key in fetchedChargerData.lot) {
                const lotValue = fetchedChargerData.lot[key];
                const formalLotValue = fetchedChargerData.formal_lot[key];
                formalLotObject[lotValue] = formalLotValue;
            }
            setFormalLotNames(formalLotObject)
        }
    }, [fetchedChargerData]);

    // 
    // This turns the data into an array for easier mapping, 
    // calculates the max amps, calculates the number of charging/
    // connected/sleeping etc. This info is used in the map and table
    // 

    useEffect(() => {
        if (!userChargerData || !userChargerData.map || !userChargerData.markers || !userChargerData.names) return;
        let usableData;
        // First try to combine the fetched data into a more usable array
        try {
            usableData = combineChargerData(userChargerData);
            // console.log(usableData)
            const clusters = usableData.map(item => item.lot);
            const uniqueLotNames = [...new Set(clusters)];
            const chargers = usableData.map(item => {
                return {
                    name: item.name,
                    id: item.id,
                    lot: item.lot,
                    chargerId: item.chargerID
                };
            });
            setSimpleChargers(chargers)
            setClusterNames(uniqueLotNames)
            setChargerDataArray(usableData);
        } catch (err) {
            console.error("Error combining fetched charger data from SWR:", err);
        }

        // 
        // If the usableData is successfully processed then get the statuses,
        // max maps, and requests for the dashboard, table, and map.
        // 

        if (usableData) {
            try {
                const chargerStatus = getAllChargerStatuses(usableData);
                setAllChargerStatuses(chargerStatus);
            } catch (err) {
                console.error("Error getting charger statuses in dashboard:", err);
            }
            try {
                const maxAmps = getMaxPowerAmps(usableData);
                const requestsNum = getNumberOfRequests(usableData);
                setMaxAmps(maxAmps);
                setRequestsNum(requestsNum);
            } catch (err) {
                console.error("Error getting Max amps and Number of requests for dashboard tiles:", err);
            }
        }
        setLoading(false);
    }, [userChargerData]);

    return (
        <>
            <Header title={"Monitor Real Time"} />
            <div className=" h-[calc(100vh-56px)] w-full flex overflow-x-hidden overflow-y-hidden">
                {loading ? (
                    <div className="">
                    </div>
                ) : (
                    <div className={`w-[75%] h-full overflow-x-hidden overflow-y-scroll dashboardScrollBar`}>
                        <div className={`grid grid-cols-12 gap-3 p-4`}>
                            <div className="col-span-12 h-5 flex items-center mt-1">
                                <h2 className="text-xl font-bold">Charger Dashboard</h2>
                            </div>
                            <ErrorBoundary fallback={<TableFallbackError />}>
                                <DashboardTable
                                    allChargerStatuses={allChargerStatuses}
                                    chargerDataArray={chargerDataArray}
                                    formalLotNames={formalLotNames}
                                />
                            </ErrorBoundary>
                            <div className="col-span-12 -mt-3"></div>
                            <DashboardGraphs
                                chargerDataArray={userChargerData}
                                clusterNames={clusterNames}
                                simpleChargers={simpleChargers}
                                formalLotNames={formalLotNames}
                            />
                            {/* <div className="flex items-center col-span-12 mt-3">
                                <h1 className="text-xl font-bold text-black dark:text-white dark:text-textWhite w-56 font-sans">Download Receipts</h1>
                            </div> */}
                        </div>
                    </div>
                )}
                <div className={`w-[25%] h-full overflow-x-hidden overflow-y-scroll bg-white dashboardScrollBar`}>
                    <MapContainer
                        isSettings={false}
                        dataArray={chargerDataArray}
                        mapSettings={"mapbox://styles/mapbox/streets-v10"}
                    />
                </div>
            </div>
            {!finished && (
                createPortal(
                    <div className={`fixed z-[9999] right-0 left-0 top-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center`}>
                        <InitialLoad />
                    </div>
                    , document.body
                )
            )}
        </>
    )
}

export default MonitorDashboard;