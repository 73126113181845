import React, { useEffect, useState } from "react";
import Cookies from "js-cookie"
import { genericSWRFetcher } from "../../helpers/apis";
import { jwtDecode } from "jwt-decode";
import useSWR from "swr";

import Graph from "./graph"

function GraphTile({
    previousChargeData,
    previousChargeDataError,
    previousChargeDataLoading
}) {

    console.log(previousChargeData)

    return (
        <div className="col-span-12 bg-white shadow-md rounded-md p-3">
            <div className="flex items-center justify-between">
                <h2 className="font-semibold text-lg">Energy Used by Day</h2>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>
            </div>
            <div className="h-96 flex pt-3">
                <div className="w-full h-full pr-3">
                    {!previousChargeDataError && !previousChargeDataLoading && (
                        <Graph
                            data={previousChargeData.data.energy_plot}
                        />
                    )}
                    {previousChargeDataError && (
                        <div className="w-full h-[332px] bg-yellow-50 border-[2px] border-yellow-400 rounded-lg flex items-center justify-center">
                            <h2>Loading / Error screen</h2>
                        </div>
                    )}
                    {previousChargeDataLoading && (
                        <div className="w-full h-[332px] bg-yellow-50 border-[2px] border-yellow-400 rounded-lg flex items-center justify-center">
                            <h2>Loading / Error screen</h2>
                        </div>
                    )}
                </div>
                {/* <div className="w-[25%] h-full pl-1">
                    <h2 className="text-sm text-gray-800">Charger:</h2>
                    <h3 className="font-semibold -mt-[2px]">Shoreline Charger 12</h3>
                    <h2 className="text-sm text-gray-800 mt-2">Energy Used:</h2>
                    <h3 className="font-semibold -mt-[2px]">13 KhW</h3>
                    <h2 className="text-sm text-gray-800 mt-2">CO2 Saved:</h2>
                    <h3 className="font-semibold -mt-[2px]">0.9 Units</h3>
                    <h2 className="text-sm text-gray-800 mt-2">Duration:</h2>
                    <h3 className="font-semibold -mt-[2px]">6 Hours 12 Minutes</h3>
                    <h2 className="text-sm text-gray-800 mt-2">Total Cost:</h2>
                    <h3 className="font-semibold -mt-[2px]">$4.18</h3>
                </div> */}
            </div>
        </div>
    )
}

export default GraphTile