import React, { useEffect, useState } from "react";
import { genericSWRFetcher, postNewCar } from "../../helpers/apis";
import useSWR from "swr";

function AddVehicle({
    decodedToken,
    refreshData,
    setAddVehicleToggle
}) {

    const [nickname, setNickname] = useState("")
    const [license, setLicense] = useState("")
    const [capacity, setCapacity] = useState(0)
    const [vehicle, setVehicle] = useState("")

    const [error, setError] = useState("")
    const [apiSuccess, setApiSuccess] = useState(false)

    const evUrl = `https://www.intellichargemobile.com:443/vehiclesettings/extra`

    const { data: evList, error: evListError, isLoading: evListLoading } = useSWR(
        evUrl, () => genericSWRFetcher(evUrl)
    );

    // Set car capacity whenever the user changes vehicle type
    useEffect(() => {
        if (vehicle === "" || evListError || evListLoading) return
        evList.data.forEach((car) => {
            if (`${car.brand}${car.model}` === vehicle) {
                setCapacity(car.Capacity)
            }
        })
    }, [vehicle])

    // Handle any missing fields, and submit new car API
    async function submitNewCarForm() {
        setError("")
        if (capacity === 0) { setError("Error: No battery capacity was found for this vehicle."); return }
        if (nickname === "") { setError("Error: Vehicle must have a nickname."); return }
        if (vehicle === "") { setError("Error: No vehicle has been selected."); return }
        if (license === "") { setError("Error: Must enter license plate."); return }
        setError("")

        const body = {
            license: license,
            nickname: nickname,
            username: decodedToken.username,
            model: vehicle
        }

        try {
            const response = await postNewCar(body)
            if (response.status === 200) {
                setApiSuccess(true)
                refreshData()
                setTimeout(() => {
                    setAddVehicleToggle(false)
                }, 1500)
            }
        } catch (error) {
            console.error("error posting new car", error)
            setError("Error: Error adding car to database.")
        }
    }

    return (
        <div className="mt-6 p-3 pt-2 border-[1px] border-gray-300 rounded-md small-bold-shadow-cars">
            <div className="flex items-center justify-between">
                <h1 className="font-semibold text-lg">Add Vehicle</h1>
                <button onClick={() => setAddVehicleToggle(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            {error !== "" && (
                <div className="w-full p-2 bg-red-100 rounded-md border-red-300 border-[1px] mb-1">
                    <h2 className="text-red-700 text-sm">{error}</h2>
                </div>
            )}
            <h4 className="text-gray-700 text-xs pl-1 pr-1">vehicle:</h4>
            <select value={vehicle} onChange={(e) => setVehicle(e.target.value)} className="w-full h-7 bg-gray-100 rounded-md border-[1px] border-gray-300 text-sm pl-2 pr-2">
                {!evListError && !evListLoading && evList.data.map((car, index) => (
                    <option onClick={() => setCapacity(car.capacity)} key={index} className="">
                        {car.brand} {car.model}
                    </option>
                ))}
            </select>
            <h4 className="text-gray-700 text-xs pl-1 pr-1 mt-1">nickname:</h4>
            <input value={nickname} onChange={(e) => setNickname(e.target.value)} type="text" className="w-full h-7 bg-gray-100 rounded-md border-[1px] border-gray-300 text-sm pl-2 pr-2"></input>
            <h4 className="text-gray-700 text-xs pl-1 pr-1 mt-1">license plate:</h4>
            <input value={license} onChange={(e) => setLicense(e.target.value)} type="text" className="w-full h-7 bg-gray-100 rounded-md border-[1px] border-gray-300 text-sm pl-2 pr-2"></input>
            <div className="w-full flex justify-end">
                {apiSuccess ? (
                    <button disabled={true} className="text-xs bg-green-700 pl-2 pr-2 p-1 text-textWhite font-semibold rounded-md mt-3">
                        Success!
                    </button>
                ) : (
                    <button onClick={() => submitNewCarForm()} className="text-xs bg-secondary pl-2 pr-2 p-1 text-textWhite font-semibold rounded-md mt-3">
                        Submit Car
                    </button>
                )}
            </div>
        </div>
    )
}

export default AddVehicle