
import React, { useEffect, useState } from "react"
import { userLogin, editUserProfile } from "../../helpers/apis"

function EditProfile({
    userInfo,
    refreshData,
    username
}) {

    const [error, setError] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const [apiSuccess, setApiSuccess] = useState(false)

    useEffect(() => {
        console.log(userInfo)
        setTimeout(() => {
            setApiSuccess(false)
        }, 1500)
    }, [userInfo])

    useEffect(() => {
        if (!userInfo) return
        setFirstname(userInfo.data.first_name)
        setLastname(userInfo.data.last_name)
        setPhoneNumber(userInfo.data.phone_number)

    }, [userInfo])

    async function submitEditProfile() {
        if (firstname === "") { setError("Error: First name field is blank."); return }
        if (lastname === "") { setError("Error: Last name field is blank."); return }
        if (phoneNumber === "") { setError("Error: Phone number field is blank."); return }
        setError("")

        try {
            const body = {
                fullname: `${firstname} ${lastname}`,
                username: username,
                phone_number: phoneNumber,
            }
            const response = await editUserProfile(body)
            if (response.status === 200) {
                setApiSuccess(true)
                refreshData()
            }
            console.log(response)
        } catch (error) {
            console.error("Error submitting graph in createGraph", error)
        }
    }


    return (
        <div className="p-3 pl-0 pr-0">
            <div className="flex items-center">
                <div className="w-[49%]">
                    <h3 className="text-gray-700 text-xs">first name:</h3>
                    <input onChange={(e) => setFirstname(e.target.value)} className="w-full border-[1px] mt-[2px] border-gray-300 text-sm p-1 h-7 rounded-md bg-gray-100" value={firstname}></input>
                </div>
                <div className="w-[49%] ml-[2%]">
                    <h3 className="text-gray-700 text-xs">last name:</h3>
                    <input onChange={(e) => setLastname(e.target.value)} className="w-full border-[1px] mt-[2px] border-gray-300 text-sm p-1 h-7 rounded-md bg-gray-100" value={lastname}></input>
                </div>
            </div>
            <h3 className="text-gray-700 text-xs mt-2">phone number:</h3>
            <input onChange={(e) => setPhoneNumber(e.target.value)} className="w-full border-[1px] mt-[2px] border-gray-300 text-sm p-1 h-7 rounded-md bg-gray-100" value={phoneNumber}></input>
            <div className="w-full flex items-center justify-end">
                {apiSuccess ? (
                    <button disabled={true} className="text-xs bg-green-700 pl-2 pr-2 p-1 text-textWhite font-semibold rounded-md mt-3">
                        Success!
                    </button>
                ) : (
                    <button onClick={() => submitEditProfile()} className="text-xs bg-secondary pl-2 pr-2 p-1 text-white font-semibold rounded-md mt-3">
                        Save Changes
                    </button>
                )}
            </div>
        </div>
    )
}

export default EditProfile  