
import { PolicyFormTimes } from "../../../../../helpers/strings";

function DayInputFields({
    day,
    energyObjects,
    handleClick,
    handleInput,
    shiftSelectedIndexes
}) {

    const selectedDayData = energyObjects.find(item => item.weekday === day)?.energy;

    return (
        <>
            <div className="rounded-lg flex grid grid-cols-8 gap-y-2 gap-x-2 pt-2">
                {selectedDayData?.map((energy, index) => (
                    <div
                        key={index}
                        onClick={(event) => handleClick(index, event)}
                        className={`flex flex-col col-span-1 items-center justify-center w-full p-[6px] pl-[5px] pr-[5px] border-[1px] rounded-md ${shiftSelectedIndexes.includes(index) ? "bg-blue-200" : "bg-gray-100"}`}
                        style={{ userSelect: 'none' }}
                    >
                        <h3 className="text-xs" style={{ userSelect: 'none' }}>{PolicyFormTimes[index]}</h3>
                        <input
                            type="number"
                            value={selectedDayData[index]}
                            className="w-10 text-sm text-center mt-[2px] h-6 rounded-[4px] border-[1px] border-gray-400 bg-white"
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            onChange={(event) => {
                                event.stopPropagation();
                                handleInput(index, event.target.value);
                            }}
                            style={{ userSelect: 'none' }}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default DayInputFields