import { useState, useEffect } from "react";
import { getPricesForEachDay } from "../../../../helpers/payment";
import { CalenderInputDays, abbreviatedDayNames } from "../../../../helpers/strings";

function SleepingInput({
    sleepingObjects,
    setSleepingObjects
}) {

    const [chargeType, setChargeType] = useState("static")
    const [selectedDay, setSelectedDay] = useState("Sunday")
    const [showUseTemplate, setShowUseTemplate] = useState(false)

    // This picks through the sleepingObjects to find if the sType is time based or not
    const findSType = (day) => {
        const dayObject = sleepingObjects.find(obj => obj.weekday === day);
        return dayObject ? dayObject.sType : null;
    };

    const findSleepingValue = (day) => {
        const dayObject = sleepingObjects.find(obj => obj.weekday === day);
        return dayObject ? dayObject.sleeping : 0;
    };

    const findGrace = (day) => {
        const dayObject = sleepingObjects.find(obj => obj.weekday === day);
        return dayObject ? dayObject.grace : 0;
    };

    // This changes the state when the user switches between Percent of Total and 
    // Static Fee
    const handleFlatChargeOptions = (e) => {
        const newChargeType = e.target.value;
        setChargeType(newChargeType);
        const newSType = newChargeType === "Percent Of Total" ? "percent" : "fee";

        setSleepingObjects(prevState =>
            prevState.map(obj =>
                obj.weekday === selectedDay ? { ...obj, sType: newSType } : obj
            )
        );
    };

    // This changes the state when the user switches between Time Based and Flat
    const handleSTypeChange = (type) => {
        setSleepingObjects(prevState =>
            prevState.map(obj =>
                obj.weekday === selectedDay ? { ...obj, sType: type } : obj
            )
        );
    };

    const handleSleepingChange = (e) => {
        const newValue = Number(e.target.value);
        setSleepingObjects(prevState =>
            prevState.map(obj =>
                obj.weekday === selectedDay ? { ...obj, sleeping: newValue } : obj
            )
        );
    };

    // This changes the grace value for each day in state
    const handleGraceChange = (e) => {
        const newValue = Number(e.target.value)
        setSleepingObjects(prevState =>
            prevState.map(obj =>
                obj.weekday === selectedDay ? { ...obj, grace: newValue } : obj
            )
        );
    };

    const duplicateDay = (day) => {
        setSleepingObjects((prevSleepingObjects) => {
            const sourceDay = prevSleepingObjects.find(obj => obj.weekday === day);
            if (!sourceDay) return prevSleepingObjects;
            return prevSleepingObjects.map((obj) => {
                if (obj.weekday === selectedDay) {
                    return { 
                        ...obj, 
                        sleeping: sourceDay.sleeping,
                        sType: sourceDay.sType,
                        grace: sourceDay.grace,
                    };
                }
                return obj;
            });
        });
    };

    const updateSTypeChange = (feeType) => {
        setSleepingObjects((prevSleepingObjects) =>
            prevSleepingObjects.map((obj) =>
                obj.weekday === selectedDay
                    ? { ...obj, sType: feeType }
                    : obj
            )
        );
    };

    console.log(sleepingObjects)
 
    return (
        <>
            <div className="w-full mt-1 rounded-lg border-[1px] border-gray-400 pb-3">
                <div className={`flex items-center justify-around bg-gray-100 h-8 border-b-[1px] border-gray-400 rounded-tr-lg rounded-tl-lg`}>
                    {CalenderInputDays.map((day, index) => (
                        <button key={index} onClick={() => setSelectedDay(day)} className={`${day === selectedDay ? "bg-blue-100 border-secondaryAccent border-[1px] font-semibold" : ""} pl-[6px] pr-[6px] mb-[2px] mt-[2px] rounded-md text-md`}>{abbreviatedDayNames[day]}</button>
                    ))}
                </div>
                <div className='w-full pl-2 pr-2'>
                    <div className="flex justify-between items-center">
                        <div className={`${showUseTemplate ? "h-8 w-48" : "h-[25px] w-16"} ease-in-out transform duration-100 w-full mt-2 flex items-center`}>
                            {showUseTemplate ? (
                                <div className="w-72 h-full bg-blue-100 border-[1px] border-secondary rounded-md flex items-center justify-between p-1 pl-2 pr-2">
                                    <div className="flex items-center h-[50%] w-full">
                                        {CalenderInputDays.map((day, index) => (
                                            <button key={index} onClick={() => duplicateDay(day)} className="text-sm font-semibold italic text-blue-800 mr-2">{abbreviatedDayNames[day]}</button>
                                        ))}
                                    </div>
                                    <button onClick={() => setShowUseTemplate(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            ) : (
                                <button onClick={() => setShowUseTemplate(true)} className="text-xs font-semibold pl-1 pr-1 p-[2px] bg-blue-100 text-secondary border-[1px] border-secondary rounded-md">
                                    Use Template
                                </button>
                            )}
                        </div>
                        {sleepingObjects.map((object, index) => {
                            if (object.weekday === selectedDay && object.sType === 'time') {
                                return (
                                    <button onClick={() => updateSTypeChange("fee")} className="w-[88px] mt-2 text-xs font-semibold pl-1 pr-1 p-[2px] bg-gray-100 text-gray-800 border-[1px] border-gray-400 rounded-md">
                                        Flat fee
                                    </button>
                                )
                            }
                            if (object.weekday === selectedDay && object.sType === 'fee') {
                                return (
                                    <button onClick={() => updateSTypeChange("time")} className="w-[88px] mt-2 text-xs font-semibold pl-1 pr-1 p-[2px] bg-gray-100 text-gray-800 border-[1px] border-gray-400 rounded-md">
                                        Time Based
                                    </button>
                                )
                            }
                            if (object.weekday === selectedDay && object.sType === 'percent') {
                                return (
                                    <button onClick={() => updateSTypeChange("time")} className="w-[88px] mt-2 text-xs font-semibold pl-1 pr-1 p-[2px] bg-gray-100 text-gray-800 border-[1px] border-gray-400 rounded-md">
                                        Time Based
                                    </button>
                                )
                            }
                        })}
                    </div>
                    <h2 className="mt-3 text-sm">Rate:</h2>
                    <div className="flex items-center mt-[2px]">
                        <div className="flex items-center border-gray-400 border-[1px] rounded-[4px] h-6 ml-0 bg-gray-100">
                            {chargeType === "Percent Of Total" ? (
                                <h2 className="ml-[8px]"></h2>
                            ) : (
                                <h2 className="ml-[8px]">$</h2>
                            )}
                            <input
                                type="number"
                                value={findSleepingValue(selectedDay)}
                                onChange={handleSleepingChange}
                                className=" w-10 pl-1 pr-1 h-6 border-[1px] border-gray-400 ml-[3px] "
                            ></input>
                            {findSType(selectedDay) !== 'time' ? (
                                <div className="flex">
                                    {chargeType === "Static Fee" ? (
                                        <h2 className="ml-[3px] pr-2 text-sm"></h2>
                                    ) : (
                                        <h2 className="ml-[3px] pr-2 text-sm mt-[2px]">%</h2>
                                    )}
                                    <select
                                        value={findSType(selectedDay) === 'percent' ? "Percent Of Total" : "Static Fee"}
                                        onChange={handleFlatChargeOptions}
                                        className="rounded-tr-[4px] border-l-[1px] text-sm  rounded-br-[4px] bg-gray-100 h-6 border-b-[1px] border-t-[1px] border-gray-400 pb-[2px]"
                                    >
                                        <option>Static Fee</option>
                                        <option>Percent Of Total</option>
                                    </select>
                                </div>
                            ) : (
                                <h2 className="ml-[3px] text-sm pr-2">/Hour</h2>
                            )}
                        </div>
                    </div>
                    <h2 className="mt-2 text-sm">Grace Period:</h2>
                    <div className="flex items-center mt-[2px]">
                        <div className="flex items-center border-gray-400 border-[1px] rounded-[4px] h-6 ml-0 bg-gray-100">
                            <input
                                onChange={handleGraceChange} type="number"
                                value={findGrace(selectedDay)}
                                className=" w-10 pl-1 pr-1 h-6 border-[1px] border-gray-400 ml-[3px] "
                            ></input>
                            <h2 className="ml-[3px] pr-2 text-sm">Hours</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SleepingInput;