
const individualCharger = {
    id: 'charger-circles',
    type: 'circle',
    source: 'chargers',
    filter: ['!', ['has', 'point_count']], 
    paint: {
        'circle-color': [
            'case',
            ['==', ['get', 'favorite'], true], '#ffcc00', 
            '#1d4ed8' 
        ],
        'circle-stroke-color': [
            'case',
            ['==', ['get', 'favorite'], true], '#ae8419',
            '#1d4ed8'
        ],
        'circle-stroke-width': [
            'case',
            ['==', ['get', 'favorite'], true], 1,
            0
        ],
        'circle-radius': 14,
    },
}

const individualChargerCount = {
    id: 'charger-counts',
    type: 'symbol',
    source: 'chargers',
    filter: ['!', ['has', 'point_count']], 
    layout: {
        'text-field': ['get', 'usage_count'],
        'text-size': 14,
    },
    paint: {
        'text-color': [
            'case',
            ['==', ['get', 'favorite'], true], '#000000',
            '#ffffff'
        ],
    },
}

const chargerCluster = {
    id: 'cluster-layer',
    type: 'circle',
    source: 'chargers',
    filter: ['has', 'point_count'], // Only show circles for clustered points
    paint: {
        'circle-color': '#ff6347',
        'circle-radius': [
            'step',
            ['get', 'point_count'],
            20, 10, 20, 50, 25, 100, 30,
        ],
    },
}

const chargerClusterCount = {
    id: 'cluster-usage-count',
    type: 'symbol',
    source: 'chargers',
    filter: ['has', 'point_count'], // Only show text for clustered points
    layout: {
        'text-field': ['get', 'sum_usage_count'], // Show aggregated usage count
        'text-size': 14,
        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
    },
    paint: {
        'text-color': '#ffffff',
    },
}
module.exports = {
    individualCharger,
    individualChargerCount,
    chargerCluster,
    chargerClusterCount
}