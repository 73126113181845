import { Routes, Route, useLocation } from "react-router-dom";
import Sidebar from "./globalComponents/sidebar";
import MonitorDashboard from "./monitor/monitorDashboard";
import LoginLanding from "./login/loginLanding";
import Payment from "./payment/payment";
import History from "./history/history";
import NewPaymentPage from "./payment/newPayment";
import Commission from "./commission/commissionPage";
import Dashboard from "./userDashboard/dashboard"
import NewLogin from "./login/newLogin";
import Test from "./testComponents/test";
import SettingsMain from "./secondaryPages/settingsMain";
import UserTable from "./userTable/userTable";

function App() {

  const location = useLocation();
  const isLoginPage = location.pathname === "/" || location.pathname === "/commissioner";

  return (
    <>
      {!isLoginPage && <Sidebar />}
      <div className={`h-[100vh] ${!isLoginPage ? "w-[calc(100vw-64px)] ml-16" : "w-[100vw]"} bg-baseWhite`}>
        <Routes>
          <Route path="/" element={<LoginLanding />} />
          <Route path="/dashboard" element={<MonitorDashboard />} />
          <Route path="/payments" element={<Payment />} />
          <Route path="/history" element={<History />} />
          <Route path="/commissioner" element={<Commission />} />
          <Route path="/userdash" element={<Dashboard />} />
          <Route path="/usertable" element={<UserTable/>}/>
          <Route path="/test" element={<Test/>}/>
          <Route path="/profile" element={<SettingsMain location={"profile"}/>}/>
          <Route path="/settings" element={<SettingsMain location={"settings"}/>}/>
        </Routes>
      </div>
    </>
  );
}
export default App;
