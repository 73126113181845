import { useState } from "react";
import { dummyNames, formatTimestamp } from "../service"

import PermitSection from "./permitSection";

function TableTile({
    user,
    request,
    index,
    checkedUsers,
    setCheckedUsers,
}) {


    console.log(user)

    const [showPermits, setShowPermits] = useState(false)

    // 
    // Add or remove checked request from state
    // 

    const handleCheckboxToggle = (username) => {
        setCheckedUsers((prevChecked) =>
            prevChecked.includes(username)
                ? prevChecked.filter((user) => user !== username)
                : [...prevChecked, username]
        );
    };

    return (
        <div
            className={`${showPermits ? "rounded-lg bg-white border-[1px] border-gray-400 small-bold-shadow mt-1 mb-1 ml-1 mr-1" : "border-b-[1px] border-gray-300 w-full"}  grid grid-cols-2 pl-3 pr-3`}
            onClick={() => handleCheckboxToggle(user.username)}
        >
            <div className="col-span-1 grid grid-cols-12 h-10 ">
                <div className="col-span-3 flex items-center">
                    <input
                        checked={checkedUsers.includes(user.username)}
                        className="w-4 h-4"
                        type="checkbox"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCheckboxToggle(user.username);
                        }}
                    />
                    <h2 className="text-sm ml-3">{user.firstname ? user.firstname : dummyNames[index]}</h2>
                </div>
                <div className="col-span-3 flex items-center">
                    <h2 className="text-sm">{user.username ? user.username : "no email"}</h2>
                </div>
                <div className="col-span-2 flex items-center justify-center">
                    <h2 className="text-sm">{user.permits[0].response ? user.permits[0].response : "no id"}</h2>
                </div>
            </div>
            <div className="col-span-1 grid grid-cols-12 h-10">
                <div className="col-span-4 flex items-center justify-between h-10">
                    <div className="w-[calc(100%-110px)] flex items-center overflow-hidden">
                        {user.permits && user.permits.length > 0 && user.permits.map((permit, index) => (
                            <h2
                                key={index}
                                className={`mr-2 text-sm ${index === user.permits.length - 1 ? "truncate overflow-hidden whitespace-nowrap" : "flex-shrink-0"
                                    }`}
                            >
                                {permit.permit},
                            </h2>
                        ))}
                    </div>
                        <button
                            className="w-[110px] italic text-sm font-semibold text-yellow-700"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowPermits(!showPermits)
                            }}
                        >
                            {showPermits ? "Hide permits" : "View permits"}
                        </button>
                </div>
            </div>
            {showPermits && (
                <PermitSection
                    user={user}
                />
            )}
        </div>

    )
}

export default TableTile