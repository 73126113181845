
module.exports = {
    newPolicyZeroArrays: {
        Sunday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Monday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Tuesday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Wednesday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Thursday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Friday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Saturday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    },

    updatedMasterObjectSetters: {
        'Mon': "Monday",
        'Tue': "Tuesday",
        'Wed': "Wednesday",
        'Thu': "Thursday",
        'Fri': "Friday",
        'Sat': "Saturday",
        'Sun': "Sunday"
    },

    indexToDayObject: {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue', 
        3: 'Wed',
        4: "Thu",
        5: 'Fri',
        6: 'Sat'
    },

    newPolicyFormEnergy: [
        { weekday: "Sunday", energy: Array(24).fill(0) },
        { weekday: "Monday", energy: Array(24).fill(0) },
        { weekday: "Tuesday", energy: Array(24).fill(0) },
        { weekday: "Wednesday", energy: Array(24).fill(0) },
        { weekday: "Thursday", energy: Array(24).fill(0) },
        { weekday: "Friday", energy: Array(24).fill(0) },
        { weekday: "Saturday", energy: Array(24).fill(0) }
    ],

    newPolicyFormParking: [
        { weekday: "Sunday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Monday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Tuesday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Wednesday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Thursday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Friday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Saturday", pFee: 'Per Hour', parking: Array(24).fill(0) }
    ],

    newPolicyFormSleeping: [
        { weekday: "Sunday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Monday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Tuesday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Wednesday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Thursday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Friday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Saturday", grace: 0, sType: 'time', sleeping: 0}
    ]


}