import { useState, useEffect } from "react"
import { CalenderInputDays, abbreviatedDayNames } from "../../../../helpers/strings";

import DayInputFields from "./energyComponents/dayInputFields";

function NewEnergyInput({
    energyObjects,
    setEnergyObjects
}) {

    const [shiftSelectedIndexes, setShiftSelectedIndexes] = useState([]);
    const [selectedDay, setSelectedDay] = useState("Sunday")
    const [showUseTemplate, setShowUseTemplate] = useState(false)

    // 
    // Reset selected indexes with every day change
    // 

    useEffect(() => {
        setShiftSelectedIndexes([])
    }, [selectedDay])

    // 
    // Handle all clicks on divs to determine if it's a shift click, or a regular click.
    // Add, remove, or ignore those index(s) 
    // 

    const handleClick = (index, event) => {
        if (event.shiftKey) {
            setShiftSelectedIndexes((prevIndexes) => {
                if (prevIndexes.length === 0) {
                    return [index];
                } else {
                    const lastSelectedIndex = prevIndexes[prevIndexes.length - 1];
                    const start = Math.min(lastSelectedIndex, index);
                    const end = Math.max(lastSelectedIndex, index);
                    const range = [];
                    for (let i = start; i <= end; i++) {
                        range.push(i);
                    }
                    return Array.from(new Set([...prevIndexes, ...range]));
                }
            });
        } else {
            if (shiftSelectedIndexes.includes(index)) {
                setShiftSelectedIndexes([]);
            } else {
                setShiftSelectedIndexes([index]);
            }
        }
    };

    // 
    // Handle input changes in DayInputFields 
    // 

    const handleInput = (index, value) => {
        setEnergyObjects((prevEnergyObjects) => {
            return prevEnergyObjects.map((obj) => {
                if (obj.weekday === selectedDay) {
                    const newEnergyArray = [...obj.energy];
                    if (!shiftSelectedIndexes.includes(index)) {
                        newEnergyArray[index] = parseFloat(value);
                    } else {
                        shiftSelectedIndexes.forEach((shiftIndex) => {
                            newEnergyArray[shiftIndex] = parseFloat(value);
                        });
                    }
                    return { ...obj, energy: newEnergyArray };
                } else {
                    return obj;
                }
            });
        });
    }

    // 
    // This allows users to duplicate days so they don't have to manually insert the same data for all days
    // 

    const duplicateDay = (day) => {
        setEnergyObjects((prevEnergyObjects) => {
            const sourceDay = prevEnergyObjects.find(obj => obj.weekday === day);
            if (!sourceDay) return prevEnergyObjects;
            return prevEnergyObjects.map((obj) => {
                if (obj.weekday === selectedDay) {
                    return { ...obj, energy: [...sourceDay.energy] };
                }
                return obj;
            });
        });
    };

    // 
    // This sets a base price that overrides all values
    // 

    const setBasePrice = (price) => {
        const newPrices = Array(24).fill(Number(price))
        setEnergyObjects((prevEnergyObjects) =>
            prevEnergyObjects.map((obj) => ({
                ...obj,
                energy: [...newPrices], 
            }))
        );
    };
    


    return (
        <div className=" bg-white border-[1px] border-gray-400 p-2 rounded-lg mt-1">
            <div className="h-8 w-full flex items-center pb-2">
                <h3 className="text-sm mr-1">Base Price:</h3>
                <div className="flex items-center border-gray-400 border-[1px] rounded-[4px] h-6 ml-2 bg-gray-100">
                    <h2 className="ml-[8px] text-sm">$</h2>
                    <input onChange={(e) => setBasePrice(e.target.value)} type="number" className=" w-10 pl-1 pr-1 h-6 border-[1px] border-gray-400 ml-[3px] " ></input>
                    <h2 className="ml-[3px] pr-2 text-sm">/kWh</h2>
                </div>
            </div>
            <div className="h-8 flex justify-between pl-3 pr-4 -ml-2 -mr-2 border-b-[1px] border-t-[1px] border-gray-400 bg-gray-100">
                {CalenderInputDays.map((day, index) => (
                    <button key={index} onClick={() => setSelectedDay(day)} className={`${day === selectedDay ? "bg-blue-100 border-secondaryAccent border-[1px] font-semibold" : ""} text-md pl-[6px] pr-[6px] mb-[2px] mt-[2px] rounded-md text-md`}>{abbreviatedDayNames[day]}</button>
                ))}
            </div>
            <div className={`${showUseTemplate ? "h-8 w-48" : "h-[25px] w-16"} ease-in-out transform duration-100 w-full mt-2 flex items-center`}>
                {showUseTemplate ? (
                    <div className="w-72 h-full bg-blue-100 border-[1px] border-secondary rounded-md flex items-center justify-between p-1 pl-2 pr-2">
                        <div className="flex items-center h-[50%] w-full">
                            {CalenderInputDays.map((day, index) => (
                                <button key={index} onClick={() => duplicateDay(day)} className="text-sm font-semibold italic text-blue-800 mr-2">{abbreviatedDayNames[day]}</button>
                            ))}
                        </div>
                        <button onClick={() => setShowUseTemplate(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                ) : (
                    <button onClick={() => setShowUseTemplate(true)} className="text-xs font-semibold pl-1 pr-1 p-[2px] bg-blue-100 text-secondary border-[1px] border-secondary rounded-md">
                        Use Template
                    </button>
                )}
            </div>
            <DayInputFields
                day={selectedDay}
                energyObjects={energyObjects}
                handleClick={handleClick}
                handleInput={handleInput}
                shiftSelectedIndexes={shiftSelectedIndexes}
            />
        </div>
    )
}

export default NewEnergyInput